import React from 'react';
import { FiArrowRight } from "react-icons/fi";

const callToActionData = {
    title: "We take complete responsibility to setup, run, manage, & grow your Schools STEM lab",
    subtitle: "Finest choice for your home & office",
    btnText: "Purchase Doob",
}

const CalltoActionTwo = () => {
    return (
        <div className="rn-callto-action bg_image bg_image_fixed" style={{marginBottom: "120px"}}>
            <div className="container">
                <div className="row row--30 align-items-center">
                    <div className="col-lg-10 offset-lg-1">
                        <div className="inner">
                            <div className="content text-center">
                                <h2>{callToActionData.title}</h2>
                                <p>One stop place for delivering best STEM education in the country</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
export default CalltoActionTwo;