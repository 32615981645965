import { React, useState } from "react";
// import { Link } from "react-router-dom";
import SEO from "../common/SEO";
import HeaderOne from "../common/header/HeaderOne";
// import HeaderTopNews from "../common/header/HeaderTopNews";
import Separator from "../elements/separator/Separator";
import SectionTitle from "../elements/sectionTitle/SectionTitle";
import FooterThree from "../common/footer/FooterThree";
import Typed from "react-typed";
import { FiArrowRight } from "react-icons/fi";
import TimelineTwo from "../elements/timeline/TimelineTwo";
import TimelinePedagogy from "../elements/timeline/TimelinePedagogy";
// import { FiArrowDown } from "react-icons/fi";
import AboutSix from "../elements/about/AboutSix";
import BrandThree from "../elements/brand/BrandThree";
import ServiceFour from "../elements/service/ServiceFour";
import VideoItem from "../elements/video/VideoItem";
import AboutTwo from "../elements/about/AboutTwo";
import CounterUpFour from "../elements/counterup/CounterUpFour";
import CalltoActionFive from "../elements/calltoaction/CalltoActionFive";
// import TestimonialOne from "../elements/testimonial/TestimonialOne";
// import GalleryOne from "../elements/gallery/GalleryOne";
// import Gallery from "../elements/gallery/Gallery";
// import CalltoActionSeven from "../elements/calltoaction/CalltoActionSeven";
// import BrandTwo from "../elements/brand/BrandTwo";
import BrandThreeImages from "../elements/brand/BrandThreeImages";
import Mission from "../elements/about/Mission";
// import TabThree from "../elements/tab/TabThree";
import HeaderTopBar from "../common/header/HeaderTopBar";
import STEM_Lab_Qwings_lessons from "../assets/propasalsdoc/STEM_Lab_Qwings_lessons.pdf";
import axios from "axios";
import PricingFive from "../elements/pricing/PricingFive";
// import BookaDemo from "./BookaDemo";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import Form from "react-bootstrap/Form";
// import VideoItem from "../elements/video/VideoItem";
// import CustomVideo from "../elements/video/CustomVideo";
import TabTwo from "../elements/tab/TabTwo";

import "../assets/css/separateCOmponentStyle.css";

// import Slider from "../elements/slider/Slider";
// import FlexItems from "./FlexItems";
// import AboutWhatWeAim from "../elements/about/AboutWhatWeAim";
// import Card from "../elements/Cards/Card";
// import ImageSlider from "../elements/slider/ImageSlider";
import CustomsVideo from "../elements/CustomeVideo/CustomVideo";
import CustomsVideoParticipations from "../elements/CustomeVideo/CustomVideoParticipations";
import TimelineOne from "../elements/timeline/TimelineOne";
import Pricing from "../elements/pricing/Pricing";
import ServiceOne from "../elements/service/ServiceOne";
import CustomImage from "../elements/customImage/CustomImage";
import BannerCarousel from "../elements/bannerCarousel/BannerCarousel";
import "../assets/css/splash.css";
import { Link } from "react-router-dom/cjs/react-router-dom.min";
import ServiceFive from "../elements/service/ServiceFive";
import SlipFour from "../elements/split/SlipFour";
import VideoOne from "../elements/video/VideoOne";
import FsLightbox from "fslightbox-react";
import CalltoActionTwo from "../elements/calltoaction/CalltoActionTwo";

axios
  .get("/getcontact")
  .then((response) => {
    // Handle response
    console.log(response);
  })
  .catch((err) => {
    // Handle errors
    console.error(err);
  });

  const PopupData = [
    {
      id: "01",
      image: "./images/bg/bg-image-4.jpg",
      popupLink: [
        "https://www.youtube.com/watch?v=khYCZhDUkCc",
      ],
    },
  ];  

export function MyVerticallyCenteredModal(props) {
  const [data, Setdata] = useState({
    schoolName: "",
    personName: "",
    mobileNumber: "",
  });
  const [isdone, Setdone] = useState(false);
  const onSubmit = () => {
    if (data.mobileNumber && data.personName && data.schoolName) {
      axios
        .post("/saveuserinfo", data)
        .then((response) => {
          // Handle response
          Setdone(true);
          // props.onHide();
        })
        .catch((err) => {
          // Handle errors
          console.error(err);
        });
      Setdata({
        schoolName: "",
        personName: "",
        mobileNumber: "",
      });
    } else {
      alert("Please Enter field Properly");
    }
  };
  const onDatachange = (e) => {
    Setdata({ ...data, [e.target.name]: e.target.value });
  };

  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header style={{ justifyContent: "center" }}>
        <Modal.Title id="contained-modal-title-vcenter">
          <span style={{ color: "whitesmoke", textAlign: "center" }}>
            {" "}
            Book a Demo
          </span>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {!isdone ? (
          <Form>
            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
              <Form.Label>School Name</Form.Label>
              <Form.Control
                type="text"
                placeholder="School name"
                autoFocus
                name="schoolName"
                onChange={onDatachange}
                value={data.schoolName}
              />
            </Form.Group>
            <Form.Group
              className="mb-3"
              controlId="exampleForm.ControlTextarea1"
            >
              <Form.Label>Person Name</Form.Label>
              <Form.Control
                type="text"
                placeholder="Rakesh Desai"
                name="personName"
                onChange={onDatachange}
                value={data.personName}
                rows={3}
              />
            </Form.Group>
            <Form.Group
              className="mb-3"
              controlId="exampleForm.ControlTextarea1"
            >
              <Form.Label>Mobile Number</Form.Label>
              <Form.Control
                type="text"
                placeholder="Enter Your Number"
                rows={3}
                name="mobileNumber"
                onChange={onDatachange}
                value={data.mobileNumber}
              />
            </Form.Group>
          </Form>
        ) : (
          <Form.Label>Thank you, we will get back to you soon.</Form.Label>
        )}
      </Modal.Body>
      <Modal.Footer>
        {!isdone ? <Button onClick={onSubmit}>Submit</Button> : null}
        {!isdone ? (
          <Button
            onClick={() => {
              props.onHide();
              Setdone(false);
            }}
          >
            Cancel
          </Button>
        ) : (
          <Button
            onClick={() => {
              props.onHide();
              Setdone(false);
            }}
          >
            Close
          </Button>
        )}
      </Modal.Footer>
    </Modal>
  );
}

const Splash = () => {
  const [modalShow, setModalShow] = useState(false);
  const [toggler, setToggler] = useState(false);
  const videoLink = [
    "https://www.youtube.com/watch?v=ed87q3LCQaY&t=83s",
  ];

  return (
    <>
      <MyVerticallyCenteredModal
        show={modalShow}
        onHide={() => setModalShow(false)}
      />
      <SEO title="Qwings" />
      <main className="page-wrapper">
        <HeaderTopBar />
        <HeaderOne
          setModalShow={setModalShow}
          btnStyle="btn-small round btn-icon"
          HeaderSTyle="header-not-transparent"
        />

        <div className="slider-area slider-style-1 bg-transparent variation-2 height-750">
          <div className="container">
            <div className="row">
              <div className="col-lg-8 order-2 order-lg-1 mt_md--40 mt_sm--40">
                <div
                  className="inner text-start"
                  style={{ marginBottom: "230px" }}
                >
                  <h1
                    className="title mobiletitle"
                    style={{ fontSize: "33px", color: "#FF731D" }}
                  >
                    {/* display-two CSs ClassName */}
                    {/* Futurising Schools  With{" "} */}
                    Deliver STEM Education <br /> The Right Way - &nbsp;
                    <Typed
                      strings={["Hands-on", "Skill-based", "Learning"]}
                      typeSpeed={80}
                      backSpeed={5}
                      backDelay={2000}
                      loop
                    />
                  </h1>
                  <p
                    className="description mobiledescription"
                    style={{ fontSize: "23px" }}
                  >
                    India's 1st STEM Ecosystem
                    <br />
                    <span style={{ color: "rgb(255, 115, 29)" }}>
                      for Schools
                    </span>
                    {/* <h4 style={{ paddingTop: "10px" }}>For All Schools</h4> */}
                  </p>

                  <div className="button-group">
                    <Link
                      to={"/contact"}
                      className="btn-default btn-medium btn-border round btn-icon"
                      // onClick={() => setModalShow(true)}
                    >
                      Book a Demo
                      <i className="icon">
                        <FiArrowRight />
                      </i>
                    </Link>
                    <button
                      className="btn-default btn-medium btn-border round btn-icon"
                      onClick={() => setToggler(!toggler)}
                    >
                      Watch Video{" "}
                      <i className="icon">
                        <FiArrowRight />
                      </i>
                    </button>
                  </div>
                  <div className="video-lightbox-wrapper">
                    <FsLightbox toggler={toggler} sources={videoLink} />
                  </div>
                </div>
              </div>
              <div className="col-lg-4 order-1 order-lg-2">
                <div className="thumbnail">
                  <img
                    src="./images/bg/home2.png"
                    alt="Banner Images"
                    style={{ transform: "scale(1.2)" }}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* End Slider Area  */}

        {/* <Separator /> */}
        <AboutSix />

        <div
          className="rn-service-area"
          style={{ paddingTop: "120px" }}
        >
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <SectionTitle
                  textAlign="text-center"
                  radiusRounded=""
                  subtitle="Four Steps to transform School"
                  title="Our Goal - for Schools"

                  // description="There are many variations of passages of Lorem Ipsum available, <br /> but the majority have suffered alteration."
                />
              </div>
            </div>
            <ServiceFour
              serviceStyle="service__style--1 icon-circle-style with-working-process"
              textAlign="text-center"
            />
          </div>
        </div> 

        <div className="rn-service-area rn-section-gap">
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <SectionTitle
                  textAlign="text-center"
                  radiusRounded=""
                  subtitle="STEM RESOURCES ALL AT ONE PLACE !"
                  title="We deploy dedicated Teams & Complete Resources in School"
                  // description=""
                />
              </div>
            </div>
            <ServiceOne
              serviceStyle="service__style--1 bg-color-blackest radius mt--25"
              textAlign="text-center"
            />
          </div>
        </div>

        <div className="our-counterup-area rn-section-gapBottom">
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <SectionTitle
                  textAlign="text-center"
                  radiusRounded=""
                  subtitle="Setup, Run, & Managed by Qwings™"
                  title="Immersive Lab at your School"
                  description=""
                />
                <div
                  // style={ { marginTop : "-4rem" } }
                  className="thumbnail splash_banner"
                >
                  <img
                    className="radius w-100"
                    src="https://res.cloudinary.com/db0vkpdye/image/upload/v1723370711/Website%20STEM%20photo%20album/Home%20page/IMG_20240518_121808_1_p54ivp.jpg"
                    alt="Images"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="rwt-video-area rn-section-gapBottom">
          <div className="container">
            {/* <div className="row mb--60">
              <CustomsVideo />
            </div> */}

            <Separator />
            <div className="mt--60">
              <CustomImage />
            </div>
          </div>
        </div>

        {/* <div className="rwt-video-area">
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <SectionTitle
                  textAlign="text-center"
                  radiusRounded=""
                  subtitle="Dedicate team works for the success of whole program"
                  title="Guided by Team of Experts & Network of Excellence"
                  // description=""
                />
              </div>
            </div>
            <TimelineTwo />
          </div>
        </div> */}


        <div className="rwt-video-area">
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <SectionTitle
                  textAlign="text-center"
                  radiusRounded=""
                  subtitle="Pedagogy to engage every students"
                  title="Curriculum for Innovation in All Domains"
                  // description=""
                />
              </div>
            </div>
            <TimelinePedagogy />
          </div>
        </div>

        <div className="rwt-video-area rn-section-gapBottom ">
          <div className="container">
            <div className="row mb--20 mt--40">
              <CustomsVideo />
            </div>
          </div>
        </div>

        <div className="our-counterup-area rn-section-gapBottom">
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <SectionTitle
                  textAlign="text-center"
                  radiusRounded=""
                  subtitle="Systematic learning journey for students"
                  title="Skill to startup Opportunity for your Students"
                  description=""
                />
                <div
                  // style={ { marginTop : "-4rem" } }
                  className="thumbnail splash_banner"
                >
                  <img
                    className="radius w-100"
                    src="https://res.cloudinary.com/db0vkpdye/image/upload/v1699368240/Website%20STEM%20photo%20album/Qwings__with_CSR-min_anrzia.webp"
                    alt="Images"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="rwt-video-area">
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <SectionTitle
                  textAlign="text-center"
                  radiusRounded=""
                  subtitle="Dedicate team works for the success of whole program"
                  title="Guided by Team of Experts & Network of Excellence"
                  // description=""
                />
              </div>
            </div>
            <TabTwo/>
          </div>
        </div>


        {/* Start Branding Area  */}
         <div className="rwt-brand-area rn-section-gap">
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <SectionTitle
                  textAlign="text-center"
                  radiusRounded=""
                  subtitle="Delivering Skills & Building Confidence for 21st Century Opportunities"
                  title="Transforming Schools, Transforming Nation"
                  description=""
                />
                <BrandThreeImages brandStyle="brand-style-2" />
              </div>
            </div>
          </div>
        </div>
        {/* End Branding Area  */}

        {/* Start Elements Area  */}
        <div className="rwt-callto-action-area">
                        <div className="wrapper">
                            <CalltoActionTwo />
                        </div>
                    </div>
                    {/* End Elements Area  */}

       
        {/* <Mission setModalShow={setModalShow} /> */}
        {/* <div className="rn-service-area rn-section-gap">
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <SectionTitle
                  textAlign="text-center"
                  radiusRounded=""
                  subtitle="We set-up, run, & upgrade STEM lab at schools"
                  title="Everything we manage @ our expenses
                  "
                  // description="There are many variations of passages of Lorem Ipsum available, <br /> but the majority have suffered alteration."
                />
              </div>
            </div>
            <ServiceFour
              serviceStyle="service__style--1 icon-circle-style with-working-process"
              textAlign="text-center"
            />
          </div>
        </div> */}

        {/* <div className="rwt-pricing-area rn-section-gapBottom">
          <div className="container">
            <div className="row mb--40 mb_sm--0">
              <div className="col-lg-12">
                <SectionTitle
                  textAlign="text-center"
                  radiusRounded=""
                  subtitle="Product Details"
                  title="About Our Product"
                  description=""
                />
              </div>
            </div>
            <PricingFive setModalShow={setModalShow} />
          </div>
        </div> */}

        {/* <div>
          <BannerCarousel />
        </div> */}

        <div>
          <Pricing />
        </div>
        {/* <AboutTwo setModalShow={setModalShow} /> */}

        {/* <div className="our-counterup-area">
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <SectionTitle
                  textAlign="text-center"
                  radiusRounded=""
                  subtitle="Immense Opportunities and Exposure to students"
                  title="Opportunities and Exposure "
                  description=""
                />
                <div className="thumbnail">
                  <img
                    className="radius w-100"
                    src="https://res.cloudinary.com/db0vkpdye/image/upload/v1699259764/Website%20STEM%20photo%20album/SVG%20files/Qwings__with_CSR-_1__fmozkn.webp"
                    alt="Images"
                  />
                </div>
                Start Elements Area 
                <div className="rwt-counterup-area ptb--60">
                  <div className="col-lg-12">
                    <CounterUpFour
                      column="col-lg-3 col-md-6 col-sm-6 col-12"
                      counterStyle="counter-style-4"
                      textALign="text-center"
                    />
                  </div>
                </div>
                End Elements Area 
              </div>
            </div>
          </div>
        </div> */}
        {/* <div className="rwt-timeline-area rn-section-gap">
                        <div className="container">
                            <div className="row">
                                <div className="col-lg-12">
                                        <SectionTitle
                                            textAlign = "text-center"
                                            radiusRounded = ""
                                            subtitle = "Timeline"
                                            title = "Working Process."
                                        />
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-lg-10 offset-lg-1 mt--50">
                                    <TimelineTwo classVar="dark-line"  />
                                </div>
                            </div>
                        </div>
                    </div>
                    <Separator /> */}
        {/* <div className="our-counterup-area">
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <div className="thumbnail">
                  <img
                    className="radius w-100"
                    src="./images/bg/process.png"
                    alt="Images"
                  />
                </div>
               
                <div className="rwt-counterup-area ptb--60">
                  <div className="col-lg-12">
                    <TimelineTwo />
                  </div>
                </div>
                
              </div>
            </div>
          </div>
        </div> */}

        {/* Start Service Area  */}

        {/* <Separator /> */}

        {/* Start Timeline Area  */}
        {/* <div className="rwt-timeline-area rn-section-gap">
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <SectionTitle
                  textAlign="text-center"
                  radiusRounded=""
                  subtitle="Timeline"
                  title="Everything we manage at our expenses"
                />
              </div>
            </div>

            <div className="row">
              <div className="col-lg-12">
                <TimelineOne />
              </div>
            </div>
          </div>
        </div> */}
        {/* End Timeline Area  */}

        {/* <Separator /> */}

        {/* <div className="rwt-callto-action-area rn-section-gapBottom">
          <div className="wrapper">
            <CalltoActionFive setModalShow={setModalShow} />
          </div>
        </div> */}

        {/* <div className="rwt-pricing-area rn-section-gap">
          <div className="container">
            <div className="row mb--40 mb_sm--0">
              <div className="col-lg-12">
                <SectionTitle
                  textAlign="text-center"
                  radiusRounded=""
                  subtitle="Product Details"
                  title="About Our Product"
                  description=""
                />
              </div>
            </div>
          
          </div>
          <Slider cardStyle="card-style-1" textAlign="text-start" />
        </div> */}

        {/* <AboutWhatWeAim setModalShow={setModalShow} /> */}

        {/* <TabThree /> */}

        {/* <div className="rwt-testimonial-area rn-section-gap">
          <div className="container">
            <div className="row">
              <div className="col-lg-12 mb--10">
                <SectionTitle
                  textAlign="text-center"
                  radiusRounded=""
                  subtitle="Client Feedback"
                  title="Testimonial."
                />
              </div>
            </div>
            <TestimonialOne
              column="col-lg-4 col-md-6 col-12 mt--30"
              teamStyle="card-style-default testimonial-style-one"
            />
          </div>
        </div> */}

        {/* <div className="rwt-gallery-area rn-section-gapBottom">
          <div className="container">
            <div className="row">
              <div className="col-lg-12 mb--20">
                <SectionTitle
                  textAlign="text-center"
                  radiusRounded=""
                  subtitle="Gallery With Lightbox"
                  title="Gallery Style Two."
                  description=""
                />
              </div>
            </div>
            <div className="row mt_dec--30 row--15">
              {PopupData.map((item) => (
                <div
                  className="col-lg-4 col-md-6 col-sm-6 col-12 mt--30"
                  key={item.id}
                >
                  <GalleryOne galleryItem={item} />
                </div>
              ))}
            </div>
          </div>
        </div> */}

        {/* <div className="rwt-callto-action-area rn-section-gap">
          <div className="wrapper">
            <div className="row">
              <div className="col-lg-12 mb--40">
                <SectionTitle
                  textAlign="text-center"
                  radiusRounded=""
                  subtitle="Call To Action"
                  title="Style Seven"
                  description=""
                />
              </div>
            </div>
            <CalltoActionSeven />
          </div>
        </div> */}

        {/* <div className="row">
          <div className="col-lg-12 mt--10">
            <BrandTwo brandStyle="brand-style-2" />
          </div>
        </div> */}

        {/* Zigtal Component */}
        {/* <div className="about-style-6 rn-section-gap">
          <div className="plr_md--30 plr_sm--30">
            <div className="wrapper theme-shape">
              <div className="row align-items-center">
                <div
                  className="row"
                  style={{
                    flex: 1,
                    textAlign: "center",
                    borderTtopLeftRradius: "20px",
                    borderTopRightRadius: "20px",
                    marginLeft: "10px",
                    marginRight: "10px",
                  }}
                >
                  <div
                    className="block-title"
                    style={{
                      background: "#0277bd",
                      color: "#fff",
                      padding: "5px 5px",
                      borderTopLeftRadius: "20px",
                      borderTopRightRadius: "20px",
                    }}
                  >
                    Employee Aspiration
                  </div>
                  <div class="block-content">
                    <div
                      class="lists"
                      style={{
                        border: "1px solid #0277bd",
                        padding: "0 20px",
                      }}
                    >
                      <span class="list">Career Growth</span>
                      <span class="list">Recognition &amp; Reward</span>
                      <span class="list">Personal Brand</span>
                      <span class="list">Continuous Learning</span>
                    </div>
                  </div>
                </div>

                <div className="col-lg-8 col-md-6 col-12 mt_sm--30">
                  <div className="inner">
                    <span className="theme-gradient subtitle">
                      I have completed 50+ project.
                    </span>
                    <h4 className="color-white maintitle">
                      Have More Projects Complete in My Carreer Life.
                    </h4>
                    <div className="read-more-btn">
                      <Link className="btn-default btn-border" to="#">
                        View More Projects
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div> */}

        {/* Start Our Demo  */}

        {/* End Our Demo  */}

        <Separator />

        {/* Start Service Area  */}
        {/* <div
          className="service-area rn-section-gapTop "
          style={{ marginBottom: "130px" }}
        >
          <div className="container">
            <div className="row">
              <div className="col-lg-6">
                <div className="content">
                  <h3 className="title">
                    Why Qwings™ STEM Lab Ecosystem for Schools
                  </h3>
                </div>
              </div>
              <div className="col-lg-6">
                <p className="mb--10">
                  Any lab will sustain only if it keeps growing and upgrading.
                  And this is not a task of a trainer alone. An entire ecosystem
                  should work with the trainer to create a thriving and growing
                  lab. An ecosystem is required to conduct continuous research
                  and development in order to introduce new activities and
                  projects to school labs. Besides handling the Trainers
                  training, STEM kits, or managing lab supplies, R&D to upgrade,
                  etc. which might otherwise divert the school's focus.
                  <br />
                  <br />
                  An ecosystem also gives students access to field experts, so
                  if they need help monetizing their skills or converting an
                  idea into a prototype, they may turn to the ecosystem.
                  <br />
                  <br />
                  An ecosystem provides a long term STEM solution to the schools
                  by giving them access to the state-of-the-art projects in the
                  STEM learning programs.
                </p>
              </div>
            </div>
          </div>
        </div> */}
        {/* End Service Area  */}
        <Separator />
          {/* Start Video Area  */}
          <div className="rwt-video-area rn-section-gapBottom">
          <div className="container">
            <div className="row">
              {PopupData.map((item) => (
                <div className="col-lg-12" key={item.id}>
                  <VideoItem galleryItem={item} />
                </div>
              ))}
            </div>
          </div>
        </div>
        {/* End Video Area  */}

        <div className="rwt-video-area rn-section-gapBottom ">
          <div className="container">
            <div className="row mb--20 mt--40">
              <CustomsVideoParticipations />
            </div>
          </div>
        </div>




        <div className="rwt-brand-area ">
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <BrandThree brandStyle="brand-style-2" />
              </div>
            </div>
          </div>
        </div>

        {/* <div className="rn-service-area rn-section-gap">
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <SectionTitle
                  textAlign="text-center"
                  radiusRounded=""
                  subtitle="What we can do for you"
                  title="Services provide for you."
                  description="There are many variations of passages "
                />
              </div>
            </div>
            <ServiceFive serviceStyle="gallery-style" textAlign="text-start" />
          </div>
        </div> */}
        <Separator />
       

        <FooterThree setModalShow={setModalShow} />
      </main>
    </>
  );
};

export default Splash;
