import React from "react";

import HeaderOne from "../common/header/HeaderOne";

import SectionTitle from "../elements/sectionTitle/SectionTitle";

import CalltoActionFive from "../elements/calltoaction/CalltoActionFive";

import Separator from "../elements/separator/Separator";
import ScrollAnimation from "react-animate-on-scroll";
import { FiArrowRight } from "react-icons/fi";
import Layout from "../common/Layout";

const data = ` Cancellation terms for Manual Subscriptions.Any refund
request after payment is made will not be accepted and no
refund will be provided. Refunds: Other than stated above
for each form of enrollment or payment method, you hereby
acknowledge and agree that QWings will not offer refunds
on any fees and charges related to your purchase of any
products or Services. This includes any partially used or
unused periods for which you have already paid, regardless
of whether it is a subscription, bundled, manual
subscription or one-time term payment method. We do not
guarantee refunds for lack of usage or dissatisfaction.
For subscription-based enrollments, upon the termination
of your subscription whether by disenrollment or
graduation or completion of program you will not be
charged for future monthly fees. However, you will not be
issued a refund for the most recently (or any previously)
charged fees. In addition, if you cancel your
subscription, and subsequently re-enroll for an Online and
/ or blended learning course , you will not be entitled to
refund. If you purchase any Online and / or blended
learning course or Service through a mobile purchase or
third-party marketplace (e.g., in-app purchases through
the Apple App Store, Android App Store or purchases made
through certain alternative payment services), the refund
policy applicable to that third-party marketplace will
apply, unless otherwise explicitly stated by QWings.
Except as otherwise explicitly stated by QWings, the
third-party marketplace will be solely responsible for
making refunds under its refund policy, and QWings will
have no refund obligations. QWings disclaims any
responsibility or liability related to any third-party
marketplace's refund policy or the third party's
compliance or noncompliance with such policy. If however,
you purchase a monthly subscription with a free trial, you
will not be eligible for a refund. Price Changes. We
reserve the right to modify, terminate, or otherwise amend
the fees and features associated with your subscription.
If we notify you in advance of at least fifteen (15) days,
your continued use of QWings and the Online and / or
blended learning course after the changes have been made
will constitute your acceptance of the changes. If you do
not wish to continue subscribing with the new fees or
features, you may terminate your subscription by
cancelling at any time. If you accept the new
subscription, its terms and conditions with these Terms
will apply for all future months. Taxes. When you purchase
any Online and / or blended learning course or other
Service or product from QWings, you agree to pay not only
the applicable fee, but also all applicable sales, use,
value added, transaction taxes, or other
government-required fees and charges that QWings
determines it is required to collect (taxes). Please note
that QWings will calculate the Estimated Taxes at checkout
and that, upon confirmation, you may be responsible for a
different total. All applicable taxes are calculated based
on the billing information you provide us at the time of
purchase. You hereby authorize QWings to modify and charge
any Taxes owed by you upon confirming the tax rate. Please
also note that where GST / VAT collection is required, if
any, GST/ VAT will be calculated and added at checkout.
You hereby agree to indemnify and hold QWings harmless
against any and all claims by any tax authority for any
underpayment of any Taxes, including, without limitation,
GST/VAT, and any related penalties and/or interest. Credit
Card Billing. Credit card payments are processed by our
third-party payment processor(s). By agreeing to this
Terms of Use, you agree to be bound by Third Party Payment
Processor(s) Terms of Service as applicable. Any breach of
those terms will be treated as a breach of these Terms. If
you have selected a subscription-based enrollment or
implemented an installment plan, your credit card will be
automatically be charged monthly for the cost of the
Online and / or blended learning course and any applicable
taxes. If you have selected a subscription-based
enrollment or implemented an installment plan, you will be
automatically charged to the Payment Method for the cost
of the Online and / or blended learning course and any
applicable taxes. If any fee is not paid in a timely
manner, or our processors are unable to process your
transaction using the credit card information provided, we
reserve the right to terminate your account or suspend or
terminate your access to the Online and / or blended
learning course as we deem appropriate. If your payment
details change, your card provider may provide us with
updated card details and you agree to our collection of
such details. We may use these new details in order to
help prevent any interruption to the Services. If you
would like to use a different payment method or if there
is a change in payment method, please visit your account
settings to update your billing information. Contact
Information You may contact us at: info@qwings.in`;

const TermsandConditions = () => {
  return (
    <>
      <Layout>
        <div className="main-content">
          {/* Start Elements Area  */}
          <div className="rwt-callto-action-area rn-section-gapBottom">
            <div className="wrapper">
              <div className="row">
                <div className="col-lg-12 mb--40">
                  <SectionTitle
                    textAlign="text-center"
                    radiusRounded=""
                    subtitle="Call To Action"
                    title="Terms & Conditions"
                    description=""
                  />
                </div>
              </div>

              <div className="rn-callto-action clltoaction-style-default style-5">
                <div className="container">
                  <div className="row row--0 align-items-center content-wrapper theme-shape">
                    <div className="col-lg-12">
                      <div className="inner">
                        <div className="content text-center">
                          {/* <ScrollAnimation
                          animateIn="fadeInUp"
                          animateOut="fadeInOut"
                          animateOnce={true}
                        >
                          <h2 className="title">{"contact Us"}</h2>
                        </ScrollAnimation> */}
                          <ScrollAnimation
                            animateIn="fadeInUp"
                            animateOut="fadeInOut"
                            animateOnce={true}
                          >
                            <h6 className="subtitle">{data}</h6>
                          </ScrollAnimation>

                          <ScrollAnimation
                            animateIn="fadeInUp"
                            animateOut="fadeInOut"
                            animateOnce={true}
                          >
                            <div className="call-to-btn text-center">
                              <a
                                className="btn-default btn-icon"
                                href="/contact"
                              >
                                {"contactus"}{" "}
                                <i className="icon">
                                  <FiArrowRight />
                                </i>
                              </a>
                            </div>
                          </ScrollAnimation>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Layout>
    </>
  );
};
export default TermsandConditions;
