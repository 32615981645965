import React from "react";
import { FiCheck } from "react-icons/fi";
import STEM_Lab_Qwings_lessons from "../../assets/propasalsdoc//STEM_Lab_Qwings_lessons.pdf";

const PricingFive = (props) => {
  return (
    <div className="row row--15">
      {/* Start PRicing Table Area  */}
      <div className="col-lg-4 col-md-6 col-12">
        <div className="rn-pricing style-5">
          <div className="pricing-table-inner">
            <div className="pricing-header">
              <h4 className="title">What we provide?</h4>
              <div className="pricing" style={{background:'#FF731D'}}>
                <div className="price-wrapper">
                  {/* <span className="currency">$</span> */}
                  <span
                    className=""
                    style={{ fontWeight: "bold", color: "white" }}
                  >
                    We Set-up STEM LAB in school
                  </span>
                </div>
                {/* <span className="subtitle">USD Per Month</span> */}
              </div>
            </div>
            <div className="pricing-body">
              <ul className="list-style--1">
                <li>
                  <FiCheck /> STEM lab kits for grade 3-10
                </li>
                <li>
                  <FiCheck /> Trainer at Qwings™ payroll
                </li>
                <li>
                  <FiCheck /> Comprehensive lesson plan
                </li>
                <li>
                  <FiCheck /> Daily lab consumables
                </li>
                <li>
                  <FiCheck /> CBSE skill modules, NEP
                </li>
                <li>
                  <FiCheck />
                  AI, Coding, Robotics, & Many more
                </li>

                <li>
                  <FiCheck />
                  Supported by IIT Madras RTBI
                </li>
              </ul>
            </div>
            <div className="pricing-footer">
              <a
                className="btn-default btn-border"
                href={STEM_Lab_Qwings_lessons}
                download
                style={{background:'#FF731D',color:'#fff'}}
              >
                Download Lesson Plan
              </a>
            </div>
          </div>
        </div>
      </div>
      {/* End PRicing Table Area  */}

      {/* Start PRicing Table Area  */}
      <div className="col-lg-4 col-md-6 col-12">
        <div className="rn-pricing style-5 active">
          <div className="pricing-table-inner">
            <div className="pricing-header">
              <h4 className="title">what is the Aim</h4>
              <div className="pricing" style={{background:'#FF731D'}}>
                <div className="price-wrapper">
                  {/* <span className="currency">$</span> */}
                  <span
                    className=""
                    style={{ fontWeight: "bold", color: "white" }}
                  >
                    Hands-on learning culture at school
                  </span>
                </div>
                {/* <span className="subtitle">USD Per Month</span> */}
              </div>
            </div>
            <div className="pricing-body">
              <ul className="list-style--1">
                <li>
                  <FiCheck />
                  Hands-on learning
                </li>
                <li>
                  <FiCheck /> Skill Development
                </li>
                <li>
                  <FiCheck /> Concepts through experiments
                </li>
                <li>
                  <FiCheck /> Students create and innovate
                </li>
                <li>
                  <FiCheck /> Lab that upgrades every year
                </li>
                <li>
                  <FiCheck /> Access to field experts
                </li>

                <li>
                  <FiCheck /> Grade-wise upskilling, Certificates
                </li>
              </ul>
            </div>
            <div className="pricing-footer">
              <button
                className="btn-default"
                onClick={() => props.setModalShow(true)}
                style={{background:'#FF731D'}}
              >
                Get Started
              </button>
            </div>
          </div>
        </div>
      </div>
      {/* End PRicing Table Area  */}

      {/* Start PRicing Table Area  */}
      <div className="col-lg-4 col-md-6 col-12">
        <div className="rn-pricing style-5">
          <div className="pricing-table-inner">
            <div className="pricing-header">
              <h4 className="title">Why Qwings™ Ecosystem</h4>
              <div className="pricing" style={{background:'#FF731D'}}>
                <div className="price-wrapper">
                  {/* <span className="currency">$</span> */}
                  <span
                    className=""
                    style={{ fontWeight: "bold", color: "white" }}
                  >
                    India's 1st STEM lab ecosystem
                  </span>
                </div>
                {/* <span className="subtitle">USD Per Month</span> */}
              </div>
            </div>
            <div className="pricing-body">
              <ul className="list-style--1">
                <li>
                  <FiCheck /> All at one place
                </li>
                <li>
                  <FiCheck />
                  Trainer, kits, consumables,
                </li>
                <li>
                  <FiCheck />
                  Lab upgrades
                </li>
                <li>
                  <FiCheck /> Complete operational support
                </li>
                <li>
                  <FiCheck /> Deep-tech STEM ecosystem
                </li>
                <li>
                  <FiCheck /> R&D for new projects & activities
                </li>
                <li>
                  <FiCheck /> +50,000 hours, +200 schools
                </li>
              </ul>
            </div>
            <div className="pricing-footer">
              <button
                className="btn-default btn-border"
                onClick={() => props.setModalShow(true)}
                style={{background:'#FF731D',color:'#fff'}}
              >
                Contact us
              </button>
            </div>
          </div>
        </div>
      </div>
      {/* End PRicing Table Area  */}
    </div>
  );
};
export default PricingFive;
