import React from 'react';
import VideoTwo from '../video/VideoTwo';
import { FiCheck } from "react-icons/fi";
import STEM_Lab_Qwings_lessons from "../../assets/propasalsdoc/STEM_Lab_Qwings_lessons.pdf";


const PopupData = [
    {
        id: "01",
        image: "./images/bg/home2.png",
        popupLink: [
            './images/bg/bg-image-4.jpg',
        ],
    }
]

const AboutFour = ({image}) => {
    return (
        <div className="about-area about-style-4 rn-section-gap">
            <div className="container">
                <div className="row row--40 align-items-center">
                    <div className="col-lg-6">
                        {PopupData.map((item) => (
                            <div className="video-btn" key={item.id}>
                                {/* <VideoTwo imagename={`${image}`} galleryItem={item} /> */}
                                <img className="w-100" src="./images/about/home2.png" alt="About Images" />
                            </div>
                        ))}
                    </div>
                    <div className="col-lg-6 mt_md--40 mt_sm--40">
                        <div className="content">
                            <div className="inner">
                                <h3 className="title">Qwings™ STEM Lab <br /> Ecosystem <strong style={{color:'rgb(255, 115, 29)'}}>Includes</strong></h3>
                                <ul className="feature-list">
                                    <li>
                                        <div className="icon" style={{background:'rgb(255, 115, 29)'}}>
                                            <FiCheck />
                                        </div>
                                        <div className="title-wrapper">
                                            <h4 className="title">STEM-Lab set-up</h4>
                                            {/* <p className="text">We deliver stem-lab kits, materials, lesson plans</p> */}
                                        </div>
                                    </li>
                                    <li>
                                        <div className="icon" style={{background:'rgb(255, 115, 29)'}}>
                                            <FiCheck />
                                        </div>
                                        <div className="title-wrapper">
                                            <h4 className="title">STEM kits</h4>
                                            {/* <p className="text">Trainer would be permanently placed in school</p> */}
                                        </div>
                                    </li>
                                    <li>
                                        <div className="icon" style={{background:'rgb(255, 115, 29)'}}>
                                            <FiCheck />
                                        </div>
                                        <div className="title-wrapper">
                                            <h4 className="title">Trainer at at Qwings™ Payroll</h4>
                                            {/* <p className="text">Trainer would be permanently placed in school</p> */}
                                        </div>
                                    </li>
                                    <li>
                                        <div className="icon" style={{background:'rgb(255, 115, 29)'}}>
                                            <FiCheck />
                                        </div>
                                        <div className="title-wrapper">
                                            <h4 className="title">Comprehensive learning program for all students</h4>
                                            {/* <p className="text">Trainer would be permanently placed in school</p> */}
                                        </div>
                                    </li>
                                    <li>
                                        <div className="icon" style={{background:'rgb(255, 115, 29)'}}> 
                                            <FiCheck />
                                        </div>
                                        <div className="title-wrapper">
                                            <h4 className="title">Daily lab consumables</h4>
                                            {/* <p className="text">Trainer would be permanently placed in school</p> */}
                                        </div>
                                    </li>
                                    <li>
                                        <div className="icon" style={{background:'rgb(255, 115, 29)'}}>
                                            <FiCheck />
                                        </div>
                                        <div className="title-wrapper">
                                            <h4 className="title">Continious lab upgrades</h4>
                                            {/* <p className="text">Trainer would be permanently placed in school</p> */}
                                        </div>
                                    </li>
                                    <li>
                                        <div className="icon" style={{background:'rgb(255, 115, 29)'}}>
                                            <FiCheck />
                                        </div>
                                        <div className="title-wrapper">
                                            <h4 className="title">Certificate</h4>
                                            {/* <p className="text">Trainer would be permanently placed in school</p> */}
                                        </div>
                                    </li>
                                </ul>
                                <div className="about-btn mt--30">
                                    <a className="btn-default" href={STEM_Lab_Qwings_lessons} download style={{background:'#FF731D'}}>Download the proposal</a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default AboutFour
