import React from "react";
import ScrollAnimation from "react-animate-on-scroll";
import { Link } from "react-router-dom";
import { FiArrowRight, FiCheck } from "react-icons/fi";
import { FiFacebook, FiTwitter, FiInstagram, FiLinkedin } from "react-icons/fi";

const social = {
  url: "https://www.linkedin.com/in/ankit-jaiswal-89a64855/",
  icon: <FiLinkedin />
};

const AboutFounder = ({ setModalShow }) => {
  return (
    <div className="rwt-about-area about-style-2 rn-section-gap">
      <div className="container">
        <div className="row row--30 align-items-center">
          <div className="text-center">
            <ScrollAnimation
              animateIn="fadeInUp"
              animateOut="fadeInOut"
              animateOnce={true}
            >
              <h4 className="subtitle">
                <span className="theme-gradient">We the team</span>
              </h4>
              <h2 className="title mt--10">Message from Founder</h2>
            </ScrollAnimation>
          </div>

          <div className="col-lg-3" style={{ textAlign: "center" }}>
            <div className="thumbnail">
              <img
                style={{
                  border: "1px solid black",
                  borderRadius: "5px",
                  width: "12em",
                }}
                src="./images/about/ankit4.jpg"
                alt="About Images"
              />
            </div>
            <span>
               <a href={`${social.url}`}>{social.icon}</a>
            </span>
          </div>

          <div className="col-lg-8 mt_md--30 mt_sm--30">
            <div className="content">
              <div className="section-title">
                <ScrollAnimation
                  animateIn="fadeInUp"
                  animateOut="fadeInOut"
                  animateOnce={true}
                >
                  <br />
                  <p>
                    <h4>
                      Ankit Jaiswal <br />
                      <p>Founder and CEO</p>
                    </h4>
                  </p>

                  <p>
                    Qwings™ has a vision of evolving the education with the
                    uniue model of skill oriented learning. Schools should
                    leverage the opportunity as this will lead to a confident
                    and atmanirbhar society. Students of the this decade will
                    become the workers, creators, policymakers, and innovators
                    of the 2030s and beyond. Are we preparing these future
                    innovators in the right way?
                  </p>
                  <p>
                    There is a need for skill-oriented education, also there is
                    a need to instil confidence in society that one can be a
                    creator, allowing them to aspire to do great things for
                    their communities, nation, and world.
                  </p>
                  <p>
                    With the aim of futurising school, the Qwings™ ecosystem
                    will work to make the school dynamic and vibrant in terms of
                    project based learning. The aim is to create a lab where
                    students can convert their ideas into prototypes. Delivering
                    an environment that will guide and enable school students to
                    gain hands-on working experience in industry and 21st
                    century skills, as well as work on real-life problems that
                    will help students strengthen their confidence as creators
                    for the community and the nation.
                  </p>
                  {/* <ul className="list-icon">
                                        <li><span className="icon"><FiCheck /></span> We deliver complete STEM ecosystem for Lab operations</li>
                                        <li><span className="icon"><FiCheck /></span> Continious R&D helps lab upgrades and avoids lab saturation</li>
                                        <li><span className="icon"><FiCheck /></span> Industry problem based comprehensive learning programs </li>
                                        <li><span className="icon"><FiCheck /></span> We handle Daily lab consumables at our expenses</li>
                                        <li><span className="icon"><FiCheck /></span> Field expert access for students for guidance on special projects</li>
                                    </ul> */}
                </ScrollAnimation>
                <ScrollAnimation
                  animateIn="fadeInUp"
                  animateOut="fadeInOut"
                  animateOnce={true}
                >
                  <div className="read-more-btn mt--40">
                    <a
                      className="btn-default btn-icon"
                      to="#"
                      style={{ background: "#FF731D" }}
                      onClick={() => setModalShow(true)}
                    >
                      More About Us{" "}
                      <i className="icon">
                        <FiArrowRight />
                      </i>
                    </a>
                  </div>
                </ScrollAnimation>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AboutFounder;