import { useState } from "react";
import Slide from "../../components/slide/Slide";
import GalleryOne from "../gallery/GalleryOne";
import SectionTitle from "../sectionTitle/SectionTitle";
import { FiFolderPlus, FiPlay, FiZoomIn } from "react-icons/fi";
import FsLightbox from "fslightbox-react";

export default function CustomImage() {
  //   const { image, popupLink } = {  };
  const [popupLink, setpopupLink] = useState([]);
  const iconHandel = () => {
    if (popupLink) {
      if (popupLink.length > 1) {
        if (popupLink.toString().match(/youtube/g)) {
          return <FiFolderPlus />;
        }
        return <FiFolderPlus />;
      } else if (popupLink.toString().match(/youtube/g)) {
        return <FiPlay />;
      } else {
        return <FiZoomIn />;
      }
    } else {
      return <FiZoomIn />;
    }
  };
  const [toggler, setToggler] = useState(false);

  const PopupData2 = [
    {
      id: "01",
      image: "https://res.cloudinary.com/db0vkpdye/image/upload/v1723371363/Website%20STEM%20photo%20album/Home%20page/2_jvsj00.png",
      popupLink: ["https://res.cloudinary.com/db0vkpdye/image/upload/v1723371363/Website%20STEM%20photo%20album/Home%20page/2_jvsj00.png"],
    },
    {
      id: "02",
      image: "https://res.cloudinary.com/db0vkpdye/image/upload/v1723371374/Website%20STEM%20photo%20album/Home%20page/6_fwshko.png",
      popupLink: ["https://res.cloudinary.com/db0vkpdye/image/upload/v1723371374/Website%20STEM%20photo%20album/Home%20page/6_fwshko.png"],
    },
    {
      id: "03",
      image: "https://res.cloudinary.com/db0vkpdye/image/upload/v1723458110/Website%20homepae/WhatsApp_Image_2023-10-13_at_19.08.24_rnrjub.jpg",
      popupLink: ["https://res.cloudinary.com/db0vkpdye/image/upload/v1723458110/Website%20homepae/WhatsApp_Image_2023-10-13_at_19.08.24_rnrjub.jpg"],
    },
    {
      id: "04",
      image: "https://res.cloudinary.com/db0vkpdye/image/upload/v1723371372/Website%20STEM%20photo%20album/Home%20page/10_vqbscz.png",
      popupLink: ["https://res.cloudinary.com/db0vkpdye/image/upload/v1697275237/Website%20STEM%20photo%20album/SVG%20files/5_rz4vbp.svg"],
    },
    {
      id: "05",
      image: "https://res.cloudinary.com/db0vkpdye/image/upload/v1723371375/Website%20STEM%20photo%20album/Home%20page/11_shujhn.png",
      popupLink: ["https://res.cloudinary.com/db0vkpdye/image/upload/v1697275238/Website%20STEM%20photo%20album/SVG%20files/7_umr4sm.svg"],
    },
    {
      id: "06",
      image: "https://res.cloudinary.com/db0vkpdye/image/upload/v1723371366/Website%20STEM%20photo%20album/Home%20page/8_vvhtab.png",
      popupLink: ["https://res.cloudinary.com/db0vkpdye/image/upload/v1697275238/Website%20STEM%20photo%20album/SVG%20files/9_yuftkd.svg"],
    },
    {
      id: "07",
      image: "https://res.cloudinary.com/db0vkpdye/image/upload/v1723371367/Website%20STEM%20photo%20album/Home%20page/4_zlz0vc.png",
      popupLink: ["https://res.cloudinary.com/db0vkpdye/image/upload/v1697275238/Website%20STEM%20photo%20album/SVG%20files/8_qwrxyn.svg"],
    },
    {
      id: "08",
      image: "https://res.cloudinary.com/db0vkpdye/image/upload/v1723371364/Website%20STEM%20photo%20album/Home%20page/5_mp1wig.png",
      popupLink: ["https://res.cloudinary.com/db0vkpdye/image/upload/v1697275237/Website%20STEM%20photo%20album/SVG%20files/6_tkpw9v.svg"],
    },
    {
      id: "09",
      image: "https://res.cloudinary.com/db0vkpdye/image/upload/v1723371365/Website%20STEM%20photo%20album/Home%20page/7_btrwlo.png",
      popupLink: ["https://res.cloudinary.com/db0vkpdye/image/upload/v1697275240/Website%20STEM%20photo%20album/SVG%20files/11_zmy2q8.svg"],
    },
    {
      id: "10",
      image: "https://res.cloudinary.com/db0vkpdye/image/upload/v1723371383/Website%20STEM%20photo%20album/Home%20page/12_co7v9o.png",
      popupLink: ["https://res.cloudinary.com/db0vkpdye/image/upload/v1697275240/Website%20STEM%20photo%20album/SVG%20files/12_n3atip.svg"],
    },
    {
      id: "11",
      image: "https://res.cloudinary.com/db0vkpdye/image/upload/v1723371368/Website%20STEM%20photo%20album/Home%20page/9_dqgbf6.png",
      popupLink: ["https://res.cloudinary.com/db0vkpdye/image/upload/v1697275234/Website%20STEM%20photo%20album/SVG%20files/100_bx9mv6.svg"],
    },
    {
      id: "12",
      image: "https://res.cloudinary.com/db0vkpdye/image/upload/v1697275241/Website%20STEM%20photo%20album/SVG%20files/14_ckxeri.svg",
      popupLink: ["https://res.cloudinary.com/db0vkpdye/image/upload/v1697275241/Website%20STEM%20photo%20album/SVG%20files/14_ckxeri.svg"],
    },
    {
      id: "13",
      image: "https://res.cloudinary.com/db0vkpdye/image/upload/v1697275243/Website%20STEM%20photo%20album/SVG%20files/22_t01gvc.svg",
      popupLink: ["https://res.cloudinary.com/db0vkpdye/image/upload/v1697275243/Website%20STEM%20photo%20album/SVG%20files/22_t01gvc.svg"],
    },
    {
      id: "14",
      image: "https://res.cloudinary.com/db0vkpdye/image/upload/v1697275240/Website%20STEM%20photo%20album/SVG%20files/13_ewfc55.svg",
      popupLink: ["https://res.cloudinary.com/db0vkpdye/image/upload/v1697275240/Website%20STEM%20photo%20album/SVG%20files/13_ewfc55.svg"],
    },
    {
      id: "15",
      image: "https://res.cloudinary.com/db0vkpdye/image/upload/v1697275241/Website%20STEM%20photo%20album/SVG%20files/15_rgposz.svg",
      popupLink: ["https://res.cloudinary.com/db0vkpdye/image/upload/v1697275241/Website%20STEM%20photo%20album/SVG%20files/15_rgposz.svg"],
    },
    {
      id: "16",
      image: "https://res.cloudinary.com/db0vkpdye/image/upload/v1697275242/Website%20STEM%20photo%20album/SVG%20files/16_xa4o5g.svg",
      popupLink: ["https://res.cloudinary.com/db0vkpdye/image/upload/v1697275242/Website%20STEM%20photo%20album/SVG%20files/16_xa4o5g.svg"],
    },
    {
      id: "17",
      image: "https://res.cloudinary.com/db0vkpdye/image/upload/v1697275242/Website%20STEM%20photo%20album/SVG%20files/18_pnxrfs.svg",
      popupLink: ["https://res.cloudinary.com/db0vkpdye/image/upload/v1697275242/Website%20STEM%20photo%20album/SVG%20files/18_pnxrfs.svg"],
    },
    {
      id: "18",
      image: "https://res.cloudinary.com/db0vkpdye/image/upload/v1697275243/Website%20STEM%20photo%20album/SVG%20files/19_tsbzx3.svg",
      popupLink: ["https://res.cloudinary.com/db0vkpdye/image/upload/v1697275243/Website%20STEM%20photo%20album/SVG%20files/19_tsbzx3.svg"],
    },
  ];
  return (
    <div className="rwt-gallery-area rn-section-gapBottom">
      <div className="container">
        <div className="row">
          <div className="col-lg-12">
            <SectionTitle
              textAlign="text-center"
              radiusRounded=""
              subtitle="Raw snaps from students"
              title="Next-Gen Education @ schools"
              description=""
            />
          </div>
        </div>

        <div className="row mt_dec--30 row--15 mt--30 ">
          <Slide desktop={3} tablet={2}>
            {PopupData2.map((item) => (
              <div className="ps-4 pe-2 h-100">
                <div
                  style={{
                    height: "100%",
                  }}
                >
                  <div className="h-100" key={item.id}>
                    {/* <GalleryOne galleryItem={item} /> */}
                    <div className="rn-gallery icon-center h-100">
                      <div className="thumbnail h-100">
                        <img
                          className="radius-small h-100"
                          src={`${item.image}`}
                          alt="Corporate Image"
                        />
                      </div>
                      <div className="video-icon">
                        <button
                          className="btn-default rounded-player sm-size"
                          onClick={() => {
                            setToggler(!toggler);
                            setpopupLink(item.popupLink);
                          }}
                        >
                          <span>{iconHandel()}</span>
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </Slide>
          <FsLightbox toggler={toggler} sources={popupLink} />
        </div>
      </div>
    </div>
  );
}
