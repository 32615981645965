import React from "react";

import HeaderOne from "../common/header/HeaderOne";

import SectionTitle from "../elements/sectionTitle/SectionTitle";

import CalltoActionFive from "../elements/calltoaction/CalltoActionFive";

import Separator from "../elements/separator/Separator";
import ScrollAnimation from "react-animate-on-scroll";
import { FiArrowRight } from "react-icons/fi";
import Layout from "../common/Layout";

const data = `At QWings we value your trust. In order to honor that
trust, QWings adheres to ethical grades in gathering,
using, and safeguarding any information you provide.
QWings is a leading edtech company, incorporated in India,
for imparting learning. For the sake of brevity of
understanding this Privacy Policy the company will be
hereinafter referred as QWings. This privacy policy
governs your use of the website “www.QWings.in (Website)”
and the other associated/ancillary applications, products,
websites and services managed by the Company. Please read
this privacy policy (“Policy“) carefully before using the
Application, Website, its services and products, along
with the Terms of Use (“ToU“) provided on the Application
and on the Website. Your use of the Website, Application,
or services in connection with the Application, Website or
products (“Services“), or registrations with us through
any mode or use of any products including that of SD
cards, tablets or other storage/transmitting device shall
signify your acceptance of this Policy and your agreement
to be legally bound by the same. For the sake of brevity
your use of ‘QWings’ in any electronic form or device
shall be bound by the terms and conditions enumerated and
agreed upon hereunder with willful and free consent. If
you wish not to agree with any of the terms and conditions
of this Policy, kindly refrain from using the Website,
Application or its products or until satisfaction for you
to use the same by its Sales/Marketing/Technical/Legal
Team, avail any of these services. User Provided
Information The Application/Website/Services/products
records the information you provide when you download and
register for the Application or Services or products. When
you register with us, you generally provide (a) your name,
age, email address, phone number, password and your ward’s
educational interests; (b) transaction-related
information, such as when you make purchases, respond to
any offers, or download or use applications from us; (c)
information you provide us when you contact us for help;
(d) information you enter into its system when using the
Application/Services/products, such as while asking
doubts, participating in discussions and taking tests. The
said information collected from the users could be
categorized as “Personal Information“, “Sensitive Personal
Information” and “Associated Information“. Personal
Information, Sensitive Personal Information and Associated
Information (each as individually defined under this
Information Technology (Reasonable security practices and
procedures and sensitive personal data or information)
Rules, 2011 (the “Data Protection Rules“)) shall
collectively be referred to as “Information” in this
Policy. QWings may use this Information to contact you
from time to time, to provide you with the Services,
important information, required notices and marketing
promotions. QWings will request you when it needs more
information that personally identifies you (personal
information) or allows us to contact you. QWings will not
differentiate between who is using the device to access
the Application, Website or Services or products, so long
as the log in/access credentials match with yours. In
order to make the best use of the
Application/Website/Services/products and enable your
Information to be captured accurately on the
Application/Website/Services/products, it is essential
that you have logged in using your own credentials. QWings
will, at all times, provide the option to you, not to
provide the Personal Information or Sensitive Personal
Information, which it seeks from you. Further, you can, at
any time while using the Application/Services/products,
also have an option to withdraw your consent given earlier
to it, to use such Personal Information or Sensitive
Personal Information. Withdrawal of the consent by you is
required to be sent in writing to us at the contact
details provided in this Policy below. In any such event,
QWings or the Company fully reserves the right to withdraw
further usage of the Application/Website or provide any
further Services/products there under to you.
Automatically Collected Information In addition, the
Application/products/Services may collect certain
information automatically, including, but not limited to,
the type of mobile device you use, your mobile devices
unique device ID, the IP address of your mobile device,
your mobile operating system, the type of mobile Internet
browsers you use, and information about the way you use
the Application/Services/products. As is true for most
Mobile applications, QWings also collects other relevant
information as per the permissions that you provide.
QWings uses an outside credit card processing company to
bill you for the goods and services availed by you. These
companies do not retain share, store or use personally
identifiable information of you for any other purpose. Use
of your Personal Information QWings uses the collected
Information to analyze trends, to conduct research, to
administer the Application/Services and products, to learn
about each user’s learning patterns and movements around
the Application/Services and products and to gather
demographic information and usage behavior about its user
base as a whole. Aggregated and individual, anonymized and
non-anonymized data may periodically be transmitted to
external service providers to help us improve the
Application, products and its Services. QWings will share
your information with third parties only in the ways that
are described below in this Policy. QWings may use the
individual data and behavior patterns combined with
personal information to provide you with personalized
content, and better your learning objectives/experience.
Third parties provide certain services which we may use to
analyze the data and information you provide to
personalize drive insights and help it better your
experience or reach out to you with more value added
applications, products, information and services. However,
these third party companies do not have any independent
right to share this information. QWings does not sell,
trade or rent your Information to any third party unless,
we have been expressly authorized by you either in writing
or electronically to do so. QWings may at times provide
aggregate statistics about its customers, traffic
patterns, and related site information to reputable third
parties; however this information when disclosed will be
in an aggregate form and does not contain any of your
Personally Identifiable Information. QWings will
occasionally send email notices or contact you to
communicate about its Services, products and benefits, as
they are considered an essential part of the
Services/products you have chosen. QWings may disclose
Information: as required by law, such as to comply with a
subpoena, or similar legal process; to enforce applicable
ToU, including investigation of potential violations
thereof; when it believes in good faith (doctrine of
uberrima fides) that the disclosure is necessary to
protect its rights, protect your safety or the safety of
others, investigate fraud, address security or technical
issues or respond to a government request; with its
trusted services providers who work on its behalf, do not
have an independent use of the information QWings
discloses to them, and have agreed to and adhered to the
rules set forth in this Policy; to protect against
imminent harm to the rights, property or safety of the
Application/Website/ QWings or its users or the public as
required or permitted by law; with third party service
providers in order to personalize the
Application/Website/Services/products for a better user
experience and to perform behavioral analysis; Any portion
of the Information containing personal data relating to
minors provided by you shall be deemed to be given with
the consent of the minor’s legal guardian. Such consent is
deemed to be provided by your registration with QWings.
Access to your Personal Information QWings will provide
you with the means to ensure that your Personal
Information is correct and current. If you have filled out
a user profile, it will provide an obvious way for you to
access and change your profile from its
Application/Services/Website/products. QWings adopts
stringent security measures to protect your password from
being exposed or disclosed to anyone. Only upon you
forgetting the password for its Application/Website or
Services, QWings will have a right to reset the same to
you with your prior approval as per standard web
practices. Cookies QWings send cookies (small files
containing a string of characters) to your computer,
thereby uniquely identifying your browser. Cookies are
used to track your preferences, help you login faster, and
aggregated to determine user trends. This data is used to
improve its offerings, such as providing more content in
areas of greater interest to a majority of users. Most
browsers are initially set up to accept cookies, but you
can reset your browser to refuse all cookies or to
indicate when a cookie is being sent. Disclaimer Some of
QWings features and services may not function properly if
your cookies are disabled. Links QWings may present links
in a format that enables us to keep track of whether these
links have been followed. QWings uses this information to
improve its customized content. Clicking on links may take
you to sites outside its domain. QWings are not
responsible for the privacy practices of other web sites.
QWings encourage its users to be aware when they leave its
site to read the EULA/T&Cs of each and every website that
collects personally identifiable information. This Privacy
Policy applies solely to information collected by its
Website. Alerts QWings may alert you by email or phone
(through sms/call) to inform you about new service
offerings or other information which it perceives might be
useful for you. Public Forums When you use certain
features on its website like the discussion forums and you
post or share your personal information such as comments,
messages, files, photos, will be available to all users,
and will be in the public domain. All such sharing of
information is done at your own risk. Please keep in mind
that if you disclose personal information in your profile
or when posting on its forums this information may become
publicly available. Security QWings are concerned about
safeguarding the confidentiality of your Information.
QWings provide physical, electronic, and procedural
safeguards to protect Information it processes and
maintain. An illustration being, QWings limits access to
this Information to authorized employees only who need to
know that information in order to operate, develop or
improve its Application/Services/products/Website. Please
be aware that, although QWings endeavor is to provide
stringent security, for information it possesses and
maintains, no security system can prevent all potential
security breaches. How long does QWings retain user data?
Currently, QWings retains user data while an account is
active and for at least three years afterwards. It may
alter this practice according to legal and business
requirements. For example, it may lengthen the retention
period for some data if needed to comply with law or
voluntary codes of conduct. Unless otherwise prohibited,
it may shorten the retention period for some types of data
if needed to free up storage space. Log Information When
you access QWings Website, its servers automatically
record information that your browser sends whenever you
visit a website. These server logs may include information
such as your web request, internet protocol address,
browser type, browser language, the date and time of your
request and one or more cookies that may uniquely identify
your browser. User Communications When you send an email
or other communication to QWings, it may retain those
communications in order to process your inquiries, respond
to your requests and improve our Services. Changes to this
Statement As QWings evolves, its privacy policy will need
to evolve as well to cover new situations. You are advised
to review this Policy regularly for any changes, as
continued use is deemed approval of all changes. Your
Consent QWings believes that, every user of our
Application/Services/Products/Website must be in a
position to provide an informed consent prior to providing
any Information required for the use of the
Application/Services/Products/Website. By registering with
it, you are expressly consenting to its collection,
processing, storing, disclosing and handling of your
information as set forth in this Policy now and as amended
by us. Processing, your information in any way, including,
but not limited to, collecting, storing, deleting, using,
combining, sharing, transferring and disclosing
information, all of which activities will take place in
India. If you reside outside India your information will
be transferred, processed and stored in accordance with
the applicable data protection laws of India. Contact
Information You may contact us at: info@qwings.in`;

const PrivacyPolicy = () => {
  return (
    <>
      <Layout>
        <div className="main-content">
          {/* Start Elements Area  */}
          <div className="rwt-callto-action-area rn-section-gapBottom">
            <div className="wrapper">
              <div className="row">
                <div className="col-lg-12 mb--40">
                  <SectionTitle
                    textAlign="text-center"
                    radiusRounded=""
                    subtitle="Call To Action"
                    title="Privacy & Policy"
                    description=""
                  />
                </div>
              </div>

              <div className="rn-callto-action clltoaction-style-default style-5">
                <div className="container">
                  <div className="row row--0 align-items-center content-wrapper theme-shape">
                    <div className="col-lg-12">
                      <div className="inner">
                        <div className="content text-center">
                          {/* <ScrollAnimation
                          animateIn="fadeInUp"
                          animateOut="fadeInOut"
                          animateOnce={true}
                        >
                          <h2 className="title">{"contact Us"}</h2>
                        </ScrollAnimation> */}
                          <ScrollAnimation
                            animateIn="fadeInUp"
                            animateOut="fadeInOut"
                            animateOnce={true}
                          >
                            <h6 className="subtitle">{data}</h6>
                          </ScrollAnimation>

                          <ScrollAnimation
                            animateIn="fadeInUp"
                            animateOut="fadeInOut"
                            animateOnce={true}
                          >
                            <div className="call-to-btn text-center">
                              <a
                                className="btn-default btn-icon"
                                href="/contact"
                              >
                                {"contactus"}{" "}
                                <i className="icon">
                                  <FiArrowRight />
                                </i>
                              </a>
                            </div>
                          </ScrollAnimation>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Layout>
    </>
  );
};
export default PrivacyPolicy;
