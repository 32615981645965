import class3_tracking_robot from "./images/Class_3/light-tracking-robot-removebg-preview.png"
import class3_soft_propeller_car from "./images/Class_3/soft propeller car.webp"
import class3_spinning_brush_bot from "./images/Class_3/spinning brush bot.png"
import class3_Vibrobot from "./images/Class_3/Vibrobot.jpg"
import class3_woobly_art_robot from "./images/Class_3/woobly art robot.jpg"
// Class-4
import class_4_blinking_light from "./images/Class_4/blinking light.jpg"
import class_4_dancing_bot from "./images/Class_4/dancing bot.png"
import class_4_futuristic_city from "./images/Class_4/futuristic city.jpg"
import class_4_touch_me_not from "./images/Class_4/touch me not.png"
import class_4_water_based_unmanned_boat from "./images/Class_4/water based unmanned boat.png"
// Class-5
import class_5_water_based_unmanned_boat from "./images/Class_5/auotmated door.jpg"
import class_5_automatic_dustbin_indicator from "./images/Class_5/automatic dustbin indicator.webp"
import class_5_drone_assembly_for_kids from "./images/Class_5/drone assembly for kids.webp"
import class_5_drone_joystick from "./images/Class_5/drone joystick.png"
import class_5_LED_control_using_arduino from "./images/Class_5/LED control using arduino.jpg"
import class_5_Multiple_LED_and_buzzer_control from "./images/Class_5/Multiple LED and buzzer control.webp"
import class_5_security_alarm_system from "./images/Class_5/security alarm system.jpg"
import class_5_Talking_robot from "./images/Class_5/Talking robot.webp"
import class_5_walking_robot_without_microcontroller from "./images/Class_5/walking robot without microcontroller.jpg"


// class-6
import class_6_Digitial_clock from "./images/Class_6/4-Digit Clock Display with Photon.jpg"
import class_6_Automated_dustbin from "./images/Class_6/Automated dustbin .jpg"
import class_6_Burglar_Alarm from "./images/Class_6/Burglar Alarm with PIR Motion Sensor.jpg"
import class_6_Drone_parts from "./images/Class_6/Drone parts and uses.webp"
import class_6_Line_following_robot from "./images/Class_6/Line following robot.jpg"
import class_6_Liquid_level from "./images/Class_6/Liquid level monitoring system.jpg"
import class_6_obstacleavoidancerobot from "./images/Class_6/obstacle avoidance robot.jpg"
import class_6_Smart_cradle_system from "./images/Class_6/Smart cradle system.jpg"
import class_6_smart_gas_leakage_detector_bot from "./images/Class_6/smart gas leakage detector bot.jpg"
import class_6_Smart_traffic_management_system from "./images/Class_6/Smart traffic management system.png"
import class_6_Spider_robot from "./images/Class_6/Spider robot.jpg"
import class_6_Writing_text from "./images/Class_6/Writing text on matrix board .jpg"

// class-7
import class_7_Autonomous_Soccer_robot  from "./images/Class_7/Autonomous Soccer robot .webp"
import class_7_Droneflight  from "./images/Class_7/Drone flight and component selection .jpg"
import class_7_Fire_alarm_system  from "./images/Class_7/Fire alarm system.jpg"
import class_7_Fire_extinguishing_robot   from "./images/Class_7/Fire extinguishing robot .jpg"
import class_7_Metal_detecting_robot   from "./images/Class_7/Metal detecting robot.jpg"
import class_7_Namaste_greeting_robot   from "./images/Class_7/Namaste greeting robot.jpg"
import class_7_Robo_car_with_wirelesssteering   from "./images/Class_7/Robo car with wireless steering.webp"
import class_7_Smartphone_controlled_car    from "./images/Class_7/Smartphone controlled car .jpg"
import class_7_Streetlight_Monitoring_System   from "./images/Class_7/Streetlight Monitoring System .jpg"
import class_7_Wifi_controlled   from "./images/Class_7/Wi-fi controlled robot (ESP8266).jpg"
import class_7_Wireless_IoT_weather_station   from "./images/Class_7/Wireless IoT weather station.jpg"

// Class-8
import class_8_Aibasedobjectautomous from  "./images/Class_8/AI based autonomous robot.jpg"
import class_8_Aibasedobject from "./images/Class_8/AI based object recognition robot.jpg"
import class_8_AIBASED_TALIKG_ROBOT from "./images/Class_8/AI based talking robot.webp"
import class_8_control_freiase from "./images/Class_8/Controlling LED from Firebase Database .jpg"
import class_8_face_recogition_door_lock from "./images/Class_8/Face recognition door lock.png"
import class_8_Atomation from "./images/Class_8/Home Automation using smartphone.jpg"
import class_8_AIServilence from "./images/Class_8/LIDAR based surveillance system bot.jpg"
import class_8_makingmini from "./images/Class_8/Making mini drones and flying them.jpg"
import class_8_Cm_module from "./images/Class_8/Objects recognizing Surveillance using ESP-32 cam module.png"
import class_8_robotics_arm from "./images/Class_8/Robotic ARM performing given task.webp"
import class_8_Voice_controller from "./images/Class_8/Voice controlled robot.jpg"


// Class_9


export const grades = [
  {
    name: "All Grades",
    heading: "All",
  },
  {
    name: "Grade-3",
    heading: "Curiosity Kit",
  },
  {
    name: "Grade-4",
    heading: "Beginner Kit",
  },
  {
    name: "Grade-5",
    heading: "Automation Kit",
  },
  {
    name: "Grade-6",
    heading: "Wired DIY Kit",
  },
  {
    name: "Grade-7",
    heading: "Wireless DIY",
  },
  {
    name: "Grade-8",
    heading: "AI Automation",
  },
  {
    name: "Grade-9",
    heading: "Universal Kit",
  },
  {
    name: "Grade-10",
    heading: "Ideation Kit",
  },
];

// class-3-robostic
const Class_3_RoboticsIoTImages = [
  class3_woobly_art_robot,
  class3_tracking_robot,
class3_Vibrobot,
class3_spinning_brush_bot,
class3_soft_propeller_car,
]


// Class-4_

const class_4_images = [
  class_4_blinking_light,
  class_4_dancing_bot,
  class_4_futuristic_city,
  class_4_touch_me_not,
  class_4_water_based_unmanned_boat
]

// class-5

const class_5_images = [
  class_5_walking_robot_without_microcontroller,
class_5_Talking_robot,
class_5_security_alarm_system,
class_5_Multiple_LED_and_buzzer_control,
class_5_LED_control_using_arduino,
class_5_drone_joystick,
class_5_drone_assembly_for_kids,
class_5_automatic_dustbin_indicator,
class_5_water_based_unmanned_boat

]



const class_6_images = [
  class_6_Spider_robot,
  class_6_smart_gas_leakage_detector_bot,
  class_6_obstacleavoidancerobot,
  class_6_Smart_traffic_management_system,
  class_6_Digitial_clock,
class_6_Automated_dustbin,,
class_6_Burglar_Alarm,
class_6_Drone_parts,
class_6_Line_following_robot,
class_6_Liquid_level,
class_6_Smart_cradle_system,
class_6_Writing_text
]

const class_7_images = [
  class_7_Smartphone_controlled_car,
  class_7_Fire_extinguishing_robot,
  class_7_Autonomous_Soccer_robot,
  class_7_Namaste_greeting_robot,
  class_7_Robo_car_with_wirelesssteering,
  class_7_Fire_alarm_system,
  null,
  class_7_Metal_detecting_robot,
  class_7_Streetlight_Monitoring_System,
  class_7_Wireless_IoT_weather_station,
  class_7_Droneflight,
  class_7_Wifi_controlled
  
]
const class_8_images = [
  class_8_Aibasedobjectautomous,
class_8_Voice_controller,
class_8_face_recogition_door_lock,
class_8_AIBASED_TALIKG_ROBOT,
class_8_Aibasedobject,
class_8_AIServilence,
class_8_control_freiase,
class_8_Cm_module,
class_8_robotics_arm, 
]


const RoboticsIoT = [
  "Wobbly robot art bot",
  "Light tracking bots",
  "Vibro-bots",
  "Spinning brush-bots",
  "Soft propeller car",
  "Material sound",
  "Insulator or conductor",
  "Weather station",
  "Helmet detection",
];
const Game_App_development = [
  "Color picker",
  "Tom's spinning head",
  "Talking tom",
  "Smart classroom",
  "Shoot the bug",
  "Flappy",
  "Logical operators",
  "Basketball",
  "Make a Fairytale",
  "Talking Tales",
  "Make a Clicker Game",
];

const Website_development = [
    "Introduction to website development",
"Web page tab title",
"HTML architecture",
"Birthday project"
]


const Beginners_Robotics_IoT = [
    "Blinking Lights",
"Buzzer control",
"Light sensing robot",
"Pressure Control LED",
"Dancing LED",
"Water based unmanned boat",
"Touch me not",
"Dancing bots",
"Futuristic city",
"Force sensing robot"
]
const Beginner_Game_App_development = [
    "Static chatbot",
    "Text based calculator",
    "Guess The Number game",
    "Random Nickname Generator"
];
const Beginner_Website_development= [
    "Website architecture"
];

const Beginner_Artificial_Intelligence= [
    "Introduction to AI",
"Text recognition",
"Image recognition",
"Sound recognition",
"Number recognition project",
"Mobile App block-based coding"
];


const Automation_kit_Robotics= [
    "Automatic dustbin",
"Automated door",
"Automated street lights",
"Security alarm system",
"Walking robot",
"Talking Robot",
"Writing on LCD display",
"Limitations of battery",
"LED control",
"Buzzer control",
"Drone assembly",
"RGB LEDs",
"Drone Control"

]
const Automation_Game_App_Development= [
    "Data Types",
    "Variables",
    "Loops",
    "Chatbot",
    "Calculator",
    "Nickname Generator",
    "Alarm",
    "Odd or even"
    

]

const Automation_Website_development = [
"   Theme based multi page website"

]

const Automation_Artificial_Intelligence = [
        "AI based app development"

]


const WiredDIY_Robotics = [
"Spider robot",
"Gas Leakage Detector",
"Obstacle avoidance",
"Smart Traffic Management System",
"Cradle System",
"Anti-Theft System",
"Liquid Level Monitoring",
"Line following robot 4",
"Digit Clock Display",
"Tree climbing",
"Writing text on matrix",
"Drone parts and uses",
"RFID Lock",
"Line following robot",
"4 Digit LED display 4",

]



const WIRED_Game = [
    "Python modules",
"Introduction to Arduino programming"
]

const WIRED_Website =[
    "Intro to MySQL",
"Introduction to Bootstrap"
]

const WIRED_Artificial_Intelligence =[
"    Supervised learning & unsupervised learning",
    "Play Toy Recognizer"
    
]


const Wire_less_DIYROBOTICS = [
    "Smartphone controlled car",
"Fire extinguishing robot",
"Autonomous Soccer robot",
"Namaste greeting robot",
"Car with wireless steering",
"Fire alarm system",
"Seat occupied system",
"Metal detecting robot",
"Streetlight Monitoring System",
"Wireless IoT weather station",
"Drone flight and component selection",
"Wi-fi controlled robot (ESP8266)"
]
const Wire_less_WEBSITE = [
    "Mobile view optimization",
    "Custom website with Bootstrap"
]

const Wire_less_Artificial_Intelligence = [
    "Excel based regression analysis",
"Excite, Relate, Purpose Possibilities",
"AI based autonomous robot",
"AI based object recognition robot",
"LIDAR based surveillance bot"
]

const AI_Artificial_Intelligence = [
    "AI based autonomous robot",
    "Voice controlled robot",
    "Face recognition Door lock",
    "AI based talking robot",
    "AI based object recognition robot",
    "LIDAR based surveillance system bot",
    "Controlling LED from Firebase Database",
    "Surveillance using ESP-32 cam module.",
    "Robotic ARM"
    
]

const AI_Game = [
    "Python arrays operations and libraries",
"Introduction to OpenCv",
"Ice breaker",
"Rock paper scissor",
"Mystery animal",
"Emoji scavenger hunt",
"Board games"
    
]
const AI_Blockchain = [
    "Intro to Blockchain"
    
]

const Universal_robotics = [
    "Sensor automation",
"Automated home appliances",
"Automated security system",
"Smart irrigation system",
"Wired DIY Clock display",
"Automated alarm Wire-less DIY",
"Driverless car",
"Maze solving robot",
"RFID Access system AI",
"integrated IoT Projects",
"AI based chatbot",
"AI based Irrigation system"
]


const Universal_Game = [
    "Introduction to python",
"Create a code generator",
"Build a countdown calculator",
"Write a sorting method",
"Build an interactive quiz",
"Tic-Tac-Toe by",
"Build a counter app",
"Custom python projects"
]

const Universal_Website = [
    "HTML, CSS, Bootstrap, MySQL",
"AI based based data processing",
"Introduction to Neural network",

]


const Ideation_Robotics = [
    "Sensor automation Mini project- 1 Major project -1",
"Wired DIY Mini project - 2 Major project -2",
"Wire-less DIY Mini project - 3 Major project -3",
"AI integrated IoT Projects Mini project - 4 Major project -4"
]

const Ideation_Game = [
    "Complete python introduction",
"ALEXA chatbot",
"Text to voice converter",
"Voice to text converter"
]


const Ideation_Website_development = [
    "Integrated HTML, CSS, Bootstrap, MySQL"

]

const Ideation_Artificial_Intelligence = [
    "AI project cycle",
"Neural network",
"Data processing",
"Data Evaluation"

]



export const arradata = [
  {
    name: "Grade-3",
    data: [
      {
        heading: "Robotics, IoT",
        arraydata: RoboticsIoT,
        images:Class_3_RoboticsIoTImages

      },
      {
        heading: "Game & App development",
        arraydata: Game_App_development,
        images:Class_3_RoboticsIoTImages
      },
      {
        heading: "Website development",
        arraydata: Website_development,
        images:Class_3_RoboticsIoTImages
      },
    ],
  },
  {
    name: "Grade-4",
    data: [
      {
        heading: "Robotics, IoT",
        arraydata: Beginners_Robotics_IoT,
         images:class_4_images
      },
      {
        heading: "Game & App development",
        arraydata: Beginner_Game_App_development,
        images:Class_3_RoboticsIoTImages
      },
      {
        heading: "Website development",
        arraydata: Beginner_Website_development,
        images:Class_3_RoboticsIoTImages
      },
      {
        heading: "Artificial Intelligence",
        arraydata: Beginner_Artificial_Intelligence,
        images:Class_3_RoboticsIoTImages
      },
    ],
  },
  {
    name: "Grade-5",
    data: [
      {
        heading: "Robotics, IoT",
        arraydata: Automation_kit_Robotics,
        images:class_5_images
      },
      {
        heading: "Game & App development",
        arraydata: Automation_Game_App_Development,
        images:Class_3_RoboticsIoTImages
      },
      {
        heading: "Website development",
        arraydata: Automation_Website_development,
        images:Class_3_RoboticsIoTImages
      },
      {
        heading: "Artificial Intelligence",
        arraydata: Automation_Artificial_Intelligence,
        images:Class_3_RoboticsIoTImages
      },
    ],
  },
  {
    name: "Grade-6",
    data: [
      {
        heading: "Robotics, IoT",
        arraydata: WiredDIY_Robotics,
        images:class_6_images
      },
      {
        heading: "Game & App development",
        arraydata: WIRED_Game,
        images:Class_3_RoboticsIoTImages
      },
      {
        heading: "Website development",
        arraydata: WIRED_Website,
        images:Class_3_RoboticsIoTImages
      },
      {
        heading: "Artificial Intelligence",
        arraydata: WIRED_Artificial_Intelligence,
        images:Class_3_RoboticsIoTImages
      },
    ],
  },
  {
    name: "Grade-7",
    data: [
      {
        heading: "Robotics, IoT",
        arraydata: Wire_less_DIYROBOTICS,
        images:class_7_images
      },
      {
        heading: "Website development",
        arraydata: Wire_less_WEBSITE,
        images:Class_3_RoboticsIoTImages
      },
      {
        heading: "Artificial Intelligence",
        arraydata: Wire_less_Artificial_Intelligence,
        images:Class_3_RoboticsIoTImages
      },
    ],
  },
  {
    name: "Grade-8",
    data: [
      {
        heading: "AI integrated Robotics",
        arraydata: AI_Artificial_Intelligence,
        images:class_8_images
      },
      {
        heading: "Game & App development",
        arraydata: AI_Game,
        images:Class_3_RoboticsIoTImages
      },
      {
        heading: "Blockchain",
        arraydata: AI_Blockchain,
        images:Class_3_RoboticsIoTImages
      },
    ],
  },
  
  {
    name: "Grade-9",
    data: [
      {
        heading: "Robotics, IoT",
        arraydata: Universal_robotics,
        images:Class_3_RoboticsIoTImages
      },
      {
        heading: "Game & App development",
        arraydata: Universal_Game,
        images:Class_3_RoboticsIoTImages
      },
      {
        heading: "Website development",
        arraydata: Universal_Website,
        images:Class_3_RoboticsIoTImages
      },
    ],
  },
  {
    name: "Grade-10",
    data: [
      {
        heading: "Robotics, IoT",
        arraydata: Ideation_Robotics,
        images:Class_3_RoboticsIoTImages
      },
      {
        heading: "Game & App development",
        arraydata: Ideation_Game,
        images:Class_3_RoboticsIoTImages
      },
      {
        heading: "Website development",
        arraydata: Ideation_Website_development,
        images:Class_3_RoboticsIoTImages
      },
      {
        heading: "Artificial Intelligence",
        arraydata: Ideation_Artificial_Intelligence,
        images:Class_3_RoboticsIoTImages
      },
    ],
  },
];
