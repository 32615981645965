import React from "react";
import Slide from "../../components/slide/Slide";

const BrandList = [
  {
    name: "Interview at DD Tamil",
    description: "The Qwings™ featured at DD Tamil, the impact of STEM ecosystem and how it is helping schools in running the long-term best STEM education in the country",
    image:
      "https://res.cloudinary.com/db0vkpdye/image/upload/v1723465554/Website%20homepae/achievement%20photo/31_o3z92w.png",
  },
  {
    name: "Innovation Week 2024",
    description: "Innovation Week 2024 hosted by Société Générale Global Solution Centre! Qwings™ Innovation showcased & explained ",
    image:
      "https://res.cloudinary.com/db0vkpdye/image/upload/v1723465554/Website%20homepae/achievement%20photo/33_dfdp3a.png",
  },
  {
    name: "Boeing-IITMIC Showcase",
    description: "Qwings™ participated and showcased the product and solution that how it is one stop place for running 21st century education",
    image:
      "https://res.cloudinary.com/db0vkpdye/image/upload/v1723465554/Website%20homepae/achievement%20photo/37_j9jolu.png",
  },
  {
    name: "Chief-Minister of Puducherry",
    description: "Chief minister of puducherry visiting our projects and students innovations at Qwings™ collaborated schools",
    image:
      "https://res.cloudinary.com/db0vkpdye/image/upload/v1723465554/Website%20homepae/achievement%20photo/32_kq3qqn.png",
  },
  {
    name: "Prime Minister Hon. Narendra Modi",
    description: "Qwings™ projects shortliseted for showcasing to prime minister Narendra Modi at Pariksha Pe Charcha",
    image:
      "https://res.cloudinary.com/db0vkpdye/image/upload/v1723465554/Website%20homepae/achievement%20photo/49_fxiezi.png",
  },
  {
    name: "AWS Out-of-box Idea Challenge",
    description: "1st rank to Qwings™ projects in AWS international level challenge on out-of-box idea hackathon",
    image:
      "https://res.cloudinary.com/db0vkpdye/image/upload/v1723465555/Website%20homepae/achievement%20photo/52_gfbuss.png",
  },
  {
    name: "DD National Show",
    description: "Interview by DD National Podcast in Startup Mahakumb 2024 at Bharat Mandapam, New Delhi",
    image:
      "https://res.cloudinary.com/db0vkpdye/image/upload/v1723465555/Website%20homepae/achievement%20photo/30_qmntq2.png",
  },
  {
    name: "Science Challenge Award",
    description: "Students from one of our Qwings™ collaborated schools won national level science challenge",
    image:
      "https://res.cloudinary.com/db0vkpdye/image/upload/v1723465554/Website%20homepae/achievement%20photo/50_oaj06j.png",
  },
  {
    name: "Startup-Mahakumbh",
    description: "Participated & presentated the students projects under Qwings™ solution at Startup Mahakumbh 2024",
    image:
      "https://res.cloudinary.com/db0vkpdye/image/upload/v1723465555/Website%20homepae/achievement%20photo/29_jjts5z.png",
  },
];

const BrandThreeImages = ({ brandStyle }) => {
  return (
    <div>
      <div className={`brand-list`}>
        <Slide
          desktop={4}
          tablet={2}
          mobile={1}
          autoPlay={true}
          removeArrow={true}
          ShowDots={false}
        >
          {BrandList.map((data, index) => {
            return (
              <div className="brand-item" key={index}>
                <div className="ps-4 pe-2 h-100">
                  <div
                    style={{
                      height: "100%",
                    }}
                  >
                    <div className="p-3" key={index}>
                      {/* <GalleryOne galleryItem={item} /> */}
                      <div className="rn-gallery icon-center h-100">
                        <div className="thumbnail ">
                          <img
                            className="radius-small "
                            src={`${data.image}`}
                            alt="Corporate Image"
                          />
                          <div style={{ textAlign: "center", color:"black" }}>
                            <span style={{ fontWeight: "bold" }}>
                              {data.name}
                            </span>
                            <p>{data.description}</p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            );
          })}
        </Slide>
      </div>
      {/* <ul className={brand-list ${brandStyle}}>
        {BrandList.map((data, index) => (
          <li key={index}>
            <a href="#">
              <img src={${data.image}} alt="Brand Image" />
            </a>
          </li>
        ))}
      </ul> */}
    </div>
  );
};

export default BrandThreeImages;