import React from 'react';
import basic from "../../data/pricing/basic.json";
import advanced from "../../data/pricing/advanced.json";
import personalized from "../../data/pricing/personalized.json";
import deliverables from "../../data/pricing/deliverables.json";

import { FiCheck } from "react-icons/fi";

const PricingOne = () => {
    const getfeature = ( deliverable_id ) =>{
        const val = deliverables.find( x=> x.id == deliverable_id )
        return <li><FiCheck /> {val.service} </li>
    }
    return (
        <div className="row row--30">
            {/* Start PRicing Table Area  */}
            <div className="col-lg-4 col-12 col-md-8 m-auto">
                <div className="rn-pricing">
                    <div className="pricing-table-inner">
                        <div className="pricing-header">
                            <h3 className="title ">Basic</h3>
                            {/* <div className="pricing">
                                <div className="price-wrapper">
                                    <span className="currency">INR</span>
                                    <span className="price">3600</span>
                                </div>
                                <span className="subtitle">INR Per Month</span>
                            </div> */}
                        </div>
                        <div className="pricing-body">
                            <ul className="list-style--1 text-start ps-3">
                                {
                                    basic.map( feature =>{
                                        return getfeature( feature.deliverable_id )
                                    } )
                                }
                            </ul>
                        </div>
                        <div className="pricing-footer">
                            <a className="btn-default btn-border" href="/contact">Get Quotes</a>
                        </div>
                    </div>
                </div>
            </div>
            {/* End PRicing Table Area  */}

            {/* Start PRicing Table Area  */}
            <div className="col-lg-4 col-12 col-md-8 m-auto">
                <div className="rn-pricing rn-pricing--highlight">
                    <div className="pricing-table-inner">
                        <div className="pricing-header">
                            <span className='theme-gradient mb-3 fw-bold'>Recommended</span>
                            <h3 className="title">Advanced</h3>
                            {/* <div className="pricing">
                                <div className="price-wrapper">
                                    <span className="currency">INR</span>
                                    <span className="price">4200</span>
                                </div>
                                <span className="subtitle">USD Per Month</span>
                            </div> */}
                        </div>
                        <div className="pricing-body">
                            <ul className="list-style--1">
                            <ul className="list-style--1 text-start ps-3">
                                {
                                    advanced.map( feature =>{
                                        return getfeature( feature.deliverable_id )
                                    } )
                                }
                            </ul>
                            </ul>
                        </div>
                        <div className="pricing-footer">
                            <a className="btn-default" href="/contact">Get Quotes</a>
                        </div>
                    </div>
                </div>
            </div>
            {/* End PRicing Table Area  */}

            {/* Start PRicing Table Area  */}
            <div className="col-lg-4 col-md-8 col-12 m-auto">
                <div className="rn-pricing">
                    <div className="pricing-table-inner">
                        <div className="pricing-header">
                            <h3 className="title">Personalized</h3>
                            {/* <div className="pricing">
                                <div className="price-wrapper">
                                    <span className="currency">INR</span>
                                    <span className="price">5900</span>
                                </div>
                                <span className="subtitle">INR Per Month</span>
                            </div> */}
                        </div>
                        <div className="pricing-body">
                            <ul className="list-style--1">
                            <ul className="list-style--1 text-start ps-3">
                                {
                                    personalized.map( feature =>{
                                        return getfeature( feature.deliverable_id )
                                    } )
                                }
                            </ul>
                            </ul>
                        </div>
                        <div className="pricing-footer">
                            <a className="btn-default btn-border" href="/contact">Get Quotes</a>
                        </div>
                    </div>
                </div>
            </div>
            {/* End PRicing Table Area  */}
        </div>
    )
}
export default PricingOne;
