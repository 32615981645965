import React from "react";
import ScrollAnimation from "react-animate-on-scroll";

const SlpitOne = () => {
  return (
    <div className="rn-splite-style">
      <div className="split-wrapper container">
        <div className="row no-gutters radius-10 align-items-center">
          <div className="col-lg-12 col-xl-6 col-12">
            <div className="thumbnail image-right-content">
              <img src="https://res.cloudinary.com/db0vkpdye/image/upload/v1702151047/Website%20homepae/download_1_fppt8x.jpg" alt="split Images" />
            </div>
          </div>
          <div className="col-lg-12 col-xl-6 col-12">
            <div className="split-inner">
              <ScrollAnimation
                animateIn="fadeInUp"
                animateOut="fadeInOut"
                animateOnce={true}
              >
                <h4 className="title">About Us</h4>
              </ScrollAnimation>
              <ScrollAnimation
                animateIn="fadeInUp"
                animateOut="fadeInOut"
                animateOnce={true}
              >
                <p className="description">
                  Qwings™ was founded in 2020, and is designed & developed at IIT Madras Research Park with the aim
                  to help schools in delivering 21st century education the right way!
                  
                  
                </p>
                {/* <p className="description">
                  Furthermore, we strive to offer a platform that supports and
                  guides students in turning their innovative ideas into reality
                  and startups, potentially providing them opportunity to become
                  some of the world's most successful individuals.
                </p> */}
              </ScrollAnimation>

              <ScrollAnimation
                animateIn="fadeInUp"
                animateOut="fadeInOut"
                animateOnce={true}
              >
                <ul className="split-list">
                  <li>- IIT Madras Research Park</li>
                  <li>- +91 938-97-97-281</li>
                  <li>- Founded in 2020</li>
                </ul>
              </ScrollAnimation>
              <ScrollAnimation
                animateIn="fadeInUp"
                animateOut="fadeInOut"
                animateOnce={true}
              >
                {/* <div className="view-more-button mt--35">
                  <a className="btn-default" href="/contact">
                    Contact With Us
                  </a>
                </div> */}
              </ScrollAnimation>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SlpitOne;
