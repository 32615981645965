import React, { useState } from "react";
import HeaderOne from "../../common/header/HeaderOne";
import HeaderTopBar from "../../common/header/HeaderTopBar";
import SEO from "../../common/SEO";
import SectionTitle from "../../elements/sectionTitle/SectionTitle";
import Separator from "../../elements/separator/Separator";
import FooterThree from "../../common/footer/FooterThree";
import Layout from "../../common/Layout";
import GradeForm from "../../elements/contact/GradeForm";

const HeadQuery = () => {
  const [modalShow, setModalShow] = useState(false);
  return (
    <>
      <Layout>
        <div className="row row--15 service-area ">
          <div className="row container">
            <div className="col-12">
                    {/* Start Contact Area  */}
                    <div className="rwt-contact-area rn-section-gap">
                      <div className="container">
                        <div className="row"></div>
                        <GradeForm />
                      </div>
                    </div>
                    {/* End Contact Area  */}
                  </div>
                </div>
              </div>
          
      </Layout>
    </>
  );
};

export default HeadQuery;
