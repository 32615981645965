import React,{useState,useEffect} from "react";
import { useLocation } from "react-router-dom";
import Layout from "../../common/Layout";
import SEO from "../../common/SEO";
import axios from "axios";

const Result = () => {
  return (
    <p className="success-message">
      Your Message has been successfully sent. I will contact you soon.
    </p>
  );
};
  
const ApplyPage = () =>{
    const location = useLocation()

    const [mydata,Setmydatadata] = useState(JSON.stringify(location.state))
    const [result, showresult] = useState(false);
  const [data, Setdata] = useState({
    firstname: "",
    middlename: "",
    lastname: "",
    email: "",
    mobile:''
  });

    useEffect(() => {
      Setmydatadata(location.state)
    }, [])



    const sendEmail = (e) => {
      e.preventDefault();
      // emailjs
      //   .sendForm(
      //     "service_p4x3hv8",
      //     "template_jgfr42f",
      //     e.target,
      //     "user_jrfTH2e0Ely35ZCVFdT9S"
      //   )
      //   .then(
      //     (result) => {
      //       console.log(result.text);
      //     },
      //     (error) => {
      //       console.log(error.text);
      //     }
      //   );
      axios
        .post("/hirecontat", data)
        .then((response) => {
          // Handle response
          alert("Thank You Submitted Successfully");
          Setdata({
            firstname: "",
            middlename: "",
            lastname: "",
            email: "",
            mobile:''
          });
        })
        .catch((err) => {
          // Handle errors
          console.error(err);
        });
  
      showresult(true);
    };
  
    setTimeout(() => {
      showresult(false);
    }, 5000);
    
    return(
        <>
         <div className="main-content">

        
        <SEO title="Contact || Doob - React Business  Template" />
    <Layout>
    <div
              className="container"
              style={{
                gap: "10px",
                display: "flex",
                flexDirection: "column",
              }}
            >
    <div className="rn-callto-action rn-call-to-action style-8 content-wrapper">
      <div className="container">
        <div className="row row--0 align-items-center ">
          <div className="col-lg-12">
            <div className="inner">
              <div className="content text-center">
                <h2 className="title">{mydata.title}</h2>
                <h6 className="subtitle">{mydata.paragraph}</h6>
                <div className="call-to-btn text-center mt--30">
                  <button
                    className="btn-default btn-icon"
                    target="_blank"
                    href="contact"
                    
                  >
                    Apply Now
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    
    <div className="row mt--40 row--15">
        <div className="col-lg-7">
        <form className={`contact-form-1`} action="" onSubmit={sendEmail}>
      <div className="form-group">
        <input
          type="text"
          name="firstname"
          placeholder="Your Name"
          value={data.firstname}
          required
          onChange={(e) => {
            Setdata({ ...data, [e.target.name]: e.target.value });
          }}
        />
      </div>

      {/* <div className="form-group">
                <input 
                type="email"
                name="email"
                placeholder="Email Address"
                required
                />
            </div> */}

      <div className="form-group">
        <input
          type="text"
          name="middlename"
          placeholder="Middle Name"
          value={data.middlename}
          required
          onChange={(e) => {
            Setdata({ ...data, [e.target.name]: e.target.value });
          }}
        />
      </div>

      {/* <div className="form-group">
                <input 
                type="text"
                name="subject"
                placeholder="Subject"
                required
                />
            </div> */}

      <div className="form-group">
        <input
          name="lastname"
          placeholder="Lastname"
          required
          value={data.lastname}
          onChange={(e) => {
            Setdata({ ...data, [e.target.name]: e.target.value });
          }}
        />
      </div>

      <div className="form-group">
        <input
          name="email"
          placeholder="Email"
          required
          value={data.email}
          onChange={(e) => {
            Setdata({ ...data, [e.target.name]: e.target.value });
          }}
        />
      </div>
      <div className="form-group">
        <input
          name="mobile"
          placeholder="Mobile Number"
          required
          value={data.mobile}
          onChange={(e) => {
            Setdata({ ...data, [e.target.name]: e.target.value });
          }}
        />
      </div>

      <div className="form-group">
        <button className="btn-default btn-large">Submit Now</button>
      </div>

      <div className="form-group">{result ? <Result /> : null}</div>
    </form>
        </div>
        {/* <div className="col-lg-5 mt_md--30 mt_sm--30">
          <GoogleMapStyle />
        </div> */}
      </div>
    </div>
</Layout>
         </div>
        </>
        )
}


export default ApplyPage;