import React from "react";
import ScrollAnimation from "react-animate-on-scroll";

const TimelineData = [
  {
    id: "1",
    icon: "https://res.cloudinary.com/db0vkpdye/image/upload/v1723460101/Website%20homepae/Curriculum%20image/2_prc2t7.png",
    description: "AI",
  },
  {
    id: "2",
    icon: "https://res.cloudinary.com/db0vkpdye/image/upload/v1723460099/Website%20homepae/Curriculum%20image/1_wsadsh.png",
    description: "IoT",
  },
  {
    id: "3",
    icon: "https://res.cloudinary.com/db0vkpdye/image/upload/v1723460101/Website%20homepae/Curriculum%20image/3_lejof6.png",
    description: "Robotics",
  },
  {
    id: "4",
    icon: "https://res.cloudinary.com/db0vkpdye/image/upload/v1723460097/Website%20homepae/Curriculum%20image/6_rms1al.png",
    description: "Applied Physics",
  },
  {
    id: "4",
    icon: "https://res.cloudinary.com/db0vkpdye/image/upload/v1723460100/Website%20homepae/Curriculum%20image/5_i5m4u4.png",
    description: "Drones",
  },
  {
    id: "4",
    icon: "https://res.cloudinary.com/db0vkpdye/image/upload/v1723460099/Website%20homepae/Curriculum%20image/7_wrv2hq.png",
    description: "Software",
  },
  {
    id: "4",
    icon: "https://res.cloudinary.com/db0vkpdye/image/upload/v1723460097/Website%20homepae/Curriculum%20image/4_c3wggc.png",
    description: "Metaverse",
  },
  {
    id: "4",
    icon: "https://res.cloudinary.com/db0vkpdye/image/upload/v1723460103/Website%20homepae/Curriculum%20image/9_mzlow3.png",
    description: "Rockets & Satellite",
  },
];

const TimelinePedagogy = ({ classVar }) => {
  return (
    <div className="timeline-style-two">
      <div className="row row--0">
        {/* Start Single Progress */}
        {TimelineData.map((data, index) => (
          <div
            className={`col-lg-3 col-md-2 ${classVar}`}
            key={index}
          >
            <div className="rn-timeline">
              <ScrollAnimation
                animateIn="fadeInUp"
                animateOut="fadeInOut"
                animateOnce={true}
              >
                <div className="icon" style={{ padding: "15px" }}>
                  <img className="rounded" src={data.icon} alt="" />
                </div>
                {/* <h6 className="title">{data.icon}</h6> */}
              </ScrollAnimation>
              {/* <div className="progress-line">
                <div
                  className="line-inner"
                  style={{ background: "rgb(255, 115, 29)" }}
                ></div>
              </div> */}
              {/* <div className="progress-dot">
                <div
                  className="dot-level"
                  style={{ background: "rgb(255, 115, 29)" }}
                >
                  <div className="dot-inner"></div>
                </div>
              </div> */}
              <ScrollAnimation
                animateIn="fadeInUp"
                animateOut="fadeInOut"
                animateOnce={true}
              >
                <p style={{textAlign:"center"}}
                >
                  {data.description}
                </p>
              </ScrollAnimation>
            </div>
          </div>
        ))}
        {/* End Single Progress */}
      </div>
    </div>
  );
};
export default TimelinePedagogy;