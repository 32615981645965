import ReactGA from 'react-ga';

export const initGA = () => {
  ReactGA.initialize('G-WPVBTPH3RQ'); // Replace with your Google Analytics tracking ID
}
//G-WPVBTPH3RQ

export const logPageView = () => {
  ReactGA.set({ page: window.location.pathname });
  ReactGA.pageview(window.location.pathname);
  console.log(window.location.pathname,"(window.location.pathname)")
}
