import React from "react";
import CalltoActionEight from "../../elements/calltoaction/CalltoActionEight";
import CopyrightTwo from "./CopyrightTwo";
import ScrollTop from "./ScrollTop";

const FooterThree = (props) => {
  return (
    <>
      <div className="footer-style-2">
        <CalltoActionEight setModalShow={props.setModalShow} />
        <CopyrightTwo />
      </div>
      <ScrollTop />
    </>
  );
};
export default FooterThree;
