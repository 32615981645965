import React from "react";
import SEO from "../../common/SEO";
import Layout from "../../common/Layout";
import {Link} from "react-router-dom"
import BreadcrumbOne from "../breadcrumb/BreadcrumbOne";
import SectionTitle from "../sectionTitle/SectionTitle";
import ContactOne from "./ContactOne";
import HeaderTopBar from "../../common/header/HeaderTopBar";

let jobDetails = [
  {
    title: "Marketing & Sales Representative",
    paragraph:
      "Passionate candidates having skills in marketing & sales can apply for this job role",
    link: "/",
    id:5,
  },
  {
    title: "Full Stack Developer",
    paragraph:
      "Passionate Developer looking for FullStack (MERN) which can handle multiple applicatioions can manage",
    link: "/",
    id:2,
  },
  {
    title: "Frontend Developer",
    paragraph: "React js Developer with minimum 1 year(s) experience",
    link: "/",
    id:4,
  },
  {
    title: "Graphic Designer",
    paragraph: "Candidates with minimum 1 year(s) experience in graphic designing can apply for this job role",
    link: "/",
    id:4,
  },
];

const Career = () => {
  return (
    <>
      <SEO title="Contact || Doob - React Business  Template" />
      <HeaderTopBar />
      <Layout>
        <BreadcrumbOne
          title="Open Positions"
          rootUrl="/"
          parentUrl="Home"
          currentUrl="Open Positions"
        />
        <div className="main-content">
          {/* Start Contact Area  */}
          <div className="rwt-contact-area rn-section-gap">
            <div
              className="container"
              style={{
                gap: "10px",
                display: "flex",
                flexDirection: "column",
              }}
            >
              {/* <div className="row">
                                <div className="col-lg-12 mb--40">
                                    <SectionTitle
                                        textAlign = "text-center"
                                        radiusRounded = ""
                                        subtitle = "Contact Form"
                                        title = "Our Contact Address Here."
                                        description = ""
                                    />
                                </div>
                            </div> */}

              {/* <div className="row row--15">
                  <div className="col-lg-12">
                    <div className="row row--15">
                      <div className="col-xs-6 col-sm-3  ">
                        <input type="text" placeholder="Developer" />
                      </div>
                      <div className="col-xs-6 col-sm-3  ">
                        <select value="hello">
                            <option>Hello</option>
                        </select>
                      </div>
                      <div className="col-xs-6 col-sm-3  ">
                        <input type="text" placeholder="Developer" />
                      </div>
                      <div className="col-xs-6 col-sm-3  ">
                        <input type="text" placeholder="Developer" />
                      </div>
                    </div>
                  </div>
                </div> */}

              {jobDetails
                ? jobDetails.map((job) => {
                    return (
                      <>
                        <div
                          className="rn-callto-action clltoaction-style-default style-3 bg_image"
                          style={{
                            padding: "8px",
                            backgroundColor: "#E4E6EA",
                          }}
                        >
                          <div
                            className="container"
                            style={{ backgroundColor: "#E4E6EA" }}
                          >
                            <div
                              className="row row--0 align-items-center content-wrapper"
                              style={{ backgroundColor: "#E4E6EA" }}
                            >
                              <div className="col-lg-8">
                                <div className="inner">
                                  <div className="content text-start">
                                    <h2 className="title text-black">
                                      {job.title}
                                    </h2>
                                    <h6 className="subtitle text-black">
                                      {job.paragraph}
                                    </h6>
                                  </div>
                                </div>
                              </div>
                              <div className="col-lg-4">
                                <div className="call-to-btn text-center text-lg-right">
                                  <Link
                                    className="btn-default"
                                    to={{
                                      pathname: `/career/${job.id}`,
                                      state: job, // your data array of objects
                                    }}
                                  >
                                    Apply Now
                                  </Link>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </>
                    );
                  })
                : null}
              <ContactOne />
            </div>
          </div>
          {/* End Contact Area  */}
        </div>
      </Layout>
    </>
  );
};
export default Career;
