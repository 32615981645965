import React, { useState } from "react";
import HeaderOne from "../../../common/header/HeaderOne";
import HeaderTopBar from "../../../common/header/HeaderTopBar";
import SEO from "../../../common/SEO";
import SectionTitle from "../../../elements/sectionTitle/SectionTitle";
import SlpitOne from "../../../elements/split/SlpitOne";
import Separator from "../../../elements/separator/Separator";
import FooterThree from "../../../common/footer/FooterThree";
import HeadSplit from "./HeadSplit";

const HeadQuery = () => {
  const [modalShow, setModalShow] = useState(false);
  return (
    <>
      <SEO title="About Us" />
      <main className="page-wrapper">
        <HeaderTopBar />
        <HeaderOne
          setModalShow={setModalShow}
          btnStyle="btn-small round btn-icon"
          HeaderSTyle="header-transparent"
        />

        <div className="row row--15 service-area rn-section-gapTop">
          <div className="row container">
            <div className="col-12">
              <div className="main-content">
                <div className="rwt-split-area rn-section-gap">
                  <div className="wrapper">
                    <HeadSplit />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Separator />
        <FooterThree />
      </main>
    </>
  );
};

export default HeadQuery;
