import React from "react";
import ScrollAnimation from "react-animate-on-scroll";

const TimelineData = [
  {
    id: "1",
    icon: "https://res.cloudinary.com/db0vkpdye/image/upload/v1723361679/Website%20STEM%20photo%20album/Home%20page/1_scywke.svg",
    description: "Certified Network of STEM Trainers",
  },
  {
    id: "2",
    icon: "https://res.cloudinary.com/db0vkpdye/image/upload/v1723361680/Website%20STEM%20photo%20album/Home%20page/2_pnx7kp.svg",
    description: "R&D Team Alumni of IIT Madras",
  },
  {
    id: "3",
    icon: "https://res.cloudinary.com/db0vkpdye/image/upload/v1723361690/Website%20STEM%20photo%20album/Home%20page/3_frmzxg.svg",
    description: "Quality control from IIT Madras Research Park",
  },
  {
    id: "4",
    icon: "https://res.cloudinary.com/db0vkpdye/image/upload/v1723361689/Website%20STEM%20photo%20album/Home%20page/4_kbd1qr.svg",
    description: "Connect to India's Largest Deep-Tech Startup Hub",
  },
];

const TimelineTwo = ({ classVar }) => {
  return (
    <div className="timeline-style-two bg-color-blackest">
      <div className="row row--0">
        {/* Start Single Progress */}
        {TimelineData.map((data, index) => (
          <div
            className={`col-lg-3 col-md-2 rn-timeline-single ${classVar}`}
            key={index}
          >
            <div className="rn-timeline">
              <ScrollAnimation
                animateIn="fadeInUp"
                animateOut="fadeInOut"
                animateOnce={true}
              >
                <div className="icon" style={{ padding: "15px" }}>
                  <img className="rounded" src={data.icon} alt="" />
                </div>
                {/* <h6 className="title">{data.icon}</h6> */}
              </ScrollAnimation>
              <div className="progress-line">
                <div
                  className="line-inner"
                  style={{ background: "rgb(255, 115, 29)" }}
                ></div>
              </div>
              <div className="progress-dot">
                <div
                  className="dot-level"
                  style={{ background: "rgb(255, 115, 29)" }}
                >
                  <div className="dot-inner"></div>
                </div>
              </div>
              <ScrollAnimation
                animateIn="fadeInUp"
                animateOut="fadeInOut"
                animateOnce={true}
              >
                <p className="description">{data.description}</p>
              </ScrollAnimation>
            </div>
          </div>
        ))}
        {/* End Single Progress */}
      </div>
    </div>
  );
};
export default TimelineTwo;