import React, { useState, useEffect } from "react";
import axios from "axios";
import emailjs from "emailjs-com";

const Result = () => {
  return (
    <p className="success-message">
      Your Message has been successfully sent. I will contact you soon.
    </p>
  );
};

function GradeFormField({ props, formStyle }) {
  const [result, showresult] = useState(false);

  

  setTimeout(() => {
    showresult(false);
  }, 5000);


  const [GradeData, SetGradeData] = useState({
    grade: "none",
    category: "none",
    projectname: "",
    project_image: "https://res.cloudinary.com/db0vkpdye/image/upload/v1677329533/vlhibzm3teaempssddzw.jpg",
    project__video: "",
    project_description: "",
    project_Introduction: "",
    aim: "",
    learn: "",
    brushbegin: "",
  });
  const grades = [
    "Grade-3",
    "Grade-4",
    "Grade-5",
    "Grade-6",
    "Grade-7",
    "Grade-8",
    "Grade-9",
    "Grade-10",
  ];
  const ChangeHandler = (e) => {
    SetGradeData({ ...GradeData, [e.target.name]: e.target.value });
  };

  let categories = [
    "Robotics, IoT",
    "Game & App development",
    "Website development",
    "Artificial Intelligence",
  ];

  const sendEmail = (e) => {
    e.preventDefault();
    // emailjs
    //   .sendForm(
    //     "service_p4x3hv8",
    //     "template_jgfr42f",
    //     e.target,
    //     "user_jrfTH2e0Ely35ZCVFdT9S"
    //   )
    //   .then(
    //     (result) => {
    //       console.log(result.text);
    //     },
    //     (error) => {
    //       console.log(error.text);
    //     }
    //   );
    axios
      .post("/savegradeinfo", GradeData)
      .then((response) => {
        // Handle response
        alert("Thank You Submitted Successfully");
        SetGradeData({
          grade: "none",
          category: "none",
          projectname: "",
          project_image: "https://res.cloudinary.com/db0vkpdye/image/upload/v1677329533/vlhibzm3teaempssddzw.jpg",
          project__video: "",
          project_description: "",
          project_Introduction: "",
          aim: "",
          learn: "",
          brushbegin: "",
        });
      })
      .catch((err) => {
        // Handle errors
        console.error(err);
      });

    showresult(true);
  };

  return (
    <form className={`${formStyle}`} action="" onSubmit={sendEmail}>
      <div className="form-group">
        <select value={GradeData.grade} onChange={ChangeHandler} name="grade">
            <option value={"none"}>none</option>;
          {grades.map((grade) => {
            return <option value={grade}>{grade}</option>;
          })}
        </select>
        </div>
         <div className="form-group" > 
        <select
          value={GradeData.category}
          onChange={ChangeHandler}
          name="category"
        >
            <option value={"none"}>none</option>;
          {categories.map((catgory) => {
            return <option value={catgory}>{catgory}</option>;
          })}
        </select>
      </div>

      <div className="form-group">
        <input
          type="text"
          name="projectname"
          placeholder="projectname"
          required
          onChange={ChangeHandler}
        />
      </div>

      <div className="form-group">
        <textarea
          name="project_description"
          placeholder="Your project_description"
          required
          value={GradeData.project_description}
          onChange={ChangeHandler}
          style={{ minHeight: "125px" }}
        ></textarea>
      </div>

      <div className="form-group">
        <textarea
          name="project_Introduction"
          placeholder="Your project_Introduction"
          required
          value={GradeData.project_Introduction}
          onChange={ChangeHandler}
          style={{ minHeight: "125px" }}
        ></textarea>
      </div>
      <div className="form-group">
        <textarea
          name="aim"
          placeholder="Your project_aim"
          required
          value={GradeData.aim}
          onChange={ChangeHandler}
          style={{ minHeight: "125px" }}
        ></textarea>
      </div>
      <div className="form-group">
        <textarea
          name="learn"
          placeholder="Your project_learn"
          required
          value={GradeData.learn}
          onChange={ChangeHandler}
          style={{ minHeight: "125px" }}
        ></textarea>
      </div>
      <div className="form-group">
        <textarea
          name="brushbegin"
          placeholder="Your project_brush begin"
          required
          value={GradeData.brushbegin}
          onChange={ChangeHandler}
          style={{ minHeight: "125px" }}
        ></textarea>
      </div>

      <div className="form-group">
        <button
          className="btn-default btn-large"
          style={{ background: "#FF731D" }}
        >
          Submit Now
        </button>
      </div>

      <div className="form-group">{result ? <Result /> : null}</div>
    </form>
  );
}
export default GradeFormField;
