import React from "react";
import { Link } from "react-router-dom";

const Nav = () => {
  return (
    <ul className="mainmenu link-hover ">
      <li className="has-dropdown">
        <Link to="/">Home</Link>
      </li>

      <li>
        <Link to="/about-us">About</Link>
      </li>

      <li>
        <Link to="/gallery">Gallery</Link>
      </li>

      <li className="has-droupdown">
        <Link to="/contact">Contact</Link>
      </li>

      {/* <li className="has-droupdown">
        <Link to="#">Other products</Link>
        <ul className="submenu">
          <li>
            <Link to={{ pathname: "https://qwings.org/" }} target="_blank">
              Learning platform
            </Link>
          </li>
          <li>
            <Link to={{ pathname: "https://qrobotics.in/" }} target="_blank">
              Robotics e-commerce
            </Link>
          </li>
        </ul>
      </li> */}
    </ul>
  );
};
export default Nav;
