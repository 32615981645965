import React from 'react'
import { Accordion, Card, Button } from 'react-bootstrap';

const AccordionOne = ({customStyle}) => {
    return (
        <Accordion className={`rn-accordion-style ${customStyle}`} defaultActiveKey="0" style={{width:'fit-content'}}>
            <Card>
                <Card.Header>
                <Accordion.Toggle as={Button} variant="link" eventKey="0">
                    How is it benefecial to students?
                </Accordion.Toggle>
                </Card.Header>
                <Accordion.Collapse eventKey="0">
                    <Card.Body>Students will have hands-on learning experience on 21st century skills and an opportunity
to innovate, skill monetization, and encouragement to convert their ideas into a prototype
and patents  </Card.Body>
                </Accordion.Collapse>
            </Card>

            <Card>
                <Card.Header>
                <Accordion.Toggle as={Button} variant="link" eventKey="1">
                    How is it benefecial to school?
                </Accordion.Toggle>
                </Card.Header>
                <Accordion.Collapse eventKey="1">
                    <Card.Body>Qwings™ STEM lab ecosystem is a long term STEM solution for the schools. Our STEM learning programs are designed by the pioneers in thier field. In addition, Our Continuous R&D on new projects and activities will keep school lab advancing and upgraded for many years to come. Also, school will not have to worry about lab kits, trainers, daily lab consumables, and the lab upgrades</Card.Body>
                </Accordion.Collapse>
            </Card>

            <Card>
                <Card.Header>
                <Accordion.Toggle as={Button} variant="link" eventKey="2">
                    What we need from school?
                </Accordion.Toggle>
                </Card.Header>
                <Accordion.Collapse eventKey="2">
                    <Card.Body>A approx 1000 SFT lab space, basic amenities such as chairs, tables, electricity board, 1-Projector, 5 desktops, or laptops, Mandatory yearly fee, and an MoU for 3-5 years.</Card.Body>
                </Accordion.Collapse>
            </Card>

            <Card>
                <Card.Header>
                <Accordion.Toggle as={Button} variant="link" eventKey="3">
                    What we will provide?
                </Accordion.Toggle>
                </Card.Header>
                <Accordion.Collapse eventKey="3">
                    <Card.Body>Gamified learning of AI, Coding, Data Science, IoT, CBSE skill modules, NEP modules. STEM lab kits, STEM trainer at Qwings™ Payroll, Study material, Daily lab consumables, Workshops, Entreprenuerships for kids and Certificates.   </Card.Body>
                </Accordion.Collapse>
            </Card>

        </Accordion>
    )
}
export default AccordionOne

