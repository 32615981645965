import React, { useState } from "react";
import SEO from "../../common/SEO";
import Layout from "../../common/Layout";
import { Link } from "react-router-dom";
import BreadcrumbOne from "../breadcrumb/BreadcrumbOne";
import SectionTitle from "../sectionTitle/SectionTitle";
import ContactOne from "./ContactOne";
import HeaderTopBar from "../../common/header/HeaderTopBar";
import { MyVerticallyCenteredModal } from "../../pages/Splash";

const Contact = () => {
  const [modalShow, setModalShow] = useState(false);
  return (
    <>
      <MyVerticallyCenteredModal
        show={modalShow}
        onHide={() => setModalShow(false)}
      />
      <SEO title="Contact || Doob - React Business  Template" />
      <HeaderTopBar />
      <Layout setModalShow={setModalShow}>
        <div className="breadcrumb-area breadcarumb-style-1 ptb--120">
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <div className="breadcrumb-inner text-center">
                  <h1 className="title h2" style={{ color: "#FF731D" }}>
                    We will be happy to help you <br /> Thank you for contacting
                    us.
                  </h1>
                  <ul className="page-list">
                    <li className="rn-breadcrumb-item">
                      <Link to="Home">Home</Link>
                    </li>
                    <li className="rn-breadcrumb-item active">Contact</li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="main-content">
          {/* Start Contact Area  */}
          <div className="rwt-contact-area rn-section-gap">
            <div className="container">
              <div className="row">
                <div className="col-lg-12 mb--40">
                  <SectionTitle
                    textAlign="text-center"
                    radiusRounded=""
                    subtitle="Contact Form"
                    title="Our Contact Address Here."
                    description=""
                  />
                </div>
              </div>
              <ContactOne />
            </div>
          </div>
          {/* End Contact Area  */}
        </div>
      </Layout>
    </>
  );
};
export default Contact;
