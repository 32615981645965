import React, { useState } from "react";
import FsLightbox from "fslightbox-react";
import { FiPlay } from "react-icons/fi";
import Slide from "../../components/slide/Slide";
import SectionTitle from "../sectionTitle/SectionTitle";

const PopupContent = [
    {
    id: "00",
    image: "./images/custom_video/sm-ss.png",
    popupLink: ["https://www.youtube.com/watch?v=RzHfASZAaNI"],
    desc: "Participation at Startup Mahakumbh:",
  },
  {
    id: "01",
    image: "./images/custom_video/ddnational-sc.png",
    popupLink: ["https://www.youtube.com/watch?v=qfN7aXU4gfs"],
    desc: "Featured in DD National:",
  },
  {
    id: "02",
    image: "./images/custom_video/ddtamil-sc.png",
    popupLink: ["https://www.youtube.com/watch?v=zMNVccLhY8Q"],
    desc: "Water dispenser automaton",
  },
  {
    id: "03",
    image:
    "./images/custom_video/pondycm-sc.png",
  popupLink: ["https://www.youtube.com/watch?v=UJhSGiPso4U"],
  desc: "Chief Minister of Pondicherry Visit",
  },
  {
    id: "04",
    image: "./images/custom_video/society-sc.png",
popupLink: ["https://www.youtube.com/watch?v=c70bCWgdWBM"],
    desc: "Qwings™ @ Société Générale Global",
  },
  {
    id: "05",
    image: "./images/custom_video/5.png",
    popupLink: ["https://youtu.be/TPYcF9gePsg?feature=shared"],
    desc: "Water dispenser automaton",
  },
  {
    id: "06",
    image: "./images/custom_video/6.png",
    popupLink: ["https://www.youtube.com/watch?v=OwzN9iZ8c3s"],
    desc: "Smoke detector",
  },
 
  {
    id: "07",
    image: "./images/custom_video/7.png",
    popupLink: ["https://www.youtube.com/watch?v=qost-lYtsPU"],
    desc: "Drone",
  },
 
  {
    id: "08",
    image: "./images/custom_video/8.png",
    popupLink: ["https://www.youtube.com/watch?v=E8HLCx4F7ps"],
    desc: "Gesture recognition",
  },
  {
    id: "09",
    image: "./images/custom_video/9.png",
    popupLink: ["https://www.youtube.com/watch?v=MKA4_7xpyt8&t=86s"],
    desc: "Borewell",
  },
  {
    id: "10",
    image: "./images/custom_video/10.png",
    popupLink: ["https://youtu.be/Toyx99JvC_s?feature=shared"],
    desc: "Moon Rover",
  },
  {
    id: "11",
    image: "./images/custom_video/11.png",
    popupLink: ["https://www.youtube.com/watch?v=XmmD45B7GGA"],
    desc: "Website development",
  },
  {
    id: "12",
    image: "./images/custom_video/12.png",
    popupLink: ["https://youtu.be/JWIdmrcqwnQ?feature=shared"],
    desc: "AI-based helmet detection",
  }
];


function CustomsVideoParticipations() {
  const [toggler, setToggler] = useState(false);
  const [videolink, setvideolink] = useState("");
  return (
    <div className="row row--15">
      <SectionTitle
        textAlign="text-center"
        radiusRounded=""
        subtitle=""
        title="In the Spotlight: Our Media Features & Event Highlights🚀 "
        description="&#8594"
      />
      <Slide desktop={3} tablet={2}>
        {PopupContent?.map((popup) => {
          return (
            <div  className="ps-4 pe-2 h-100">
              <div style={{ border:'0.05em solid rgba(0, 0, 0, 0.2)' , borderRadius:"0.5em" , height:'100%' }}>
              <div
                className="video-popup icon-center"
                style={{
                  backgroundImage: `url(${process.env.PUBLIC_URL}/images/bg/Pitch_Deck.png)`,
                  borderRadius:'0.5em'
                }}
              >
                <div className="thumbnail">
                  <img
                    style={{
                      borderRadius:'0.5em'
                    }}
                    src={`${popup.image}`}
                    alt="Corporate Image"
                  />
                </div>
                <div className="video-icon">
                  <button
                    className="btn-default rounded-player"
                    onClick={() => {
                      setvideolink(popup.popupLink);
                      setToggler(!toggler);
                    }}
                  >
                    <span>
                      <FiPlay />
                    </span>
                  </button>
                </div>
              </div>
              {/* <div className="pt-2 ps-2">
              <p style={{ fontWeight : '500' , fontSize : '1.75rem' }} className="text-left text-dark  ">{popup?.desc}</p>
              </div> */}
              </div>
            </div>
          );
        })}
      </Slide>
      <FsLightbox toggler={toggler} sources={videolink} />
    </div>
  );
}
export default CustomsVideoParticipations;
