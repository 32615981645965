import React from "react";
import Slide from "../../components/slide/Slide";

const BrandList = [
  {
    image: "./images/brand/2-logo.png",
  },
  {
    image: "./images/brand/3-logo.png",
  },
  {
    image: "./images/brand/4-logo.png",
  },
  {
    image: "./images/brand/5-logo-anee.png",
  },
  {
    image: "./images/brand/0-logo.png",
  },
  {
    image: "./images/brand/1-logo.png",
  },
  {
    image: "./images/brand/kidsworld.png",
  },
  {
    image: "./images/brand/9-logo.png",
  },
  {
    image: "./images/brand/10-logo.png",
  },
];

const BrandThree = ({ brandStyle }) => {
  return (
    <div>
      <div className={`brand-list `}>
        <Slide desktop={7} tablet={5} mobile={3} autoPlay={true} removeArrow={true} ShowDots = { false } >
          {BrandList.map((data, index) => {
            return(<div className="ps-4 pe-2 h-100">
            <div
              style={{
                height: "100%",
              }}
            >
              <div className="p-3" key={index}>
                {/* <GalleryOne galleryItem={item} /> */}
                <div className="rn-gallery icon-center h-100">
                  <div className="thumbnail ">
                    <img
                      className="radius-small "
                      src={`${data.image}`}
                      alt="Corporate Image"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>)
          }
          
          )}
        </Slide>
      </div>
      {/* <ul className={`brand-list ${brandStyle}`}>
        {BrandList.map((data, index) => (
          <li key={index}>
            <a href="#">
              <img src={`${data.image}`} alt="Brand Image" />
            </a>
          </li>
        ))}
      </ul> */}
    </div>
  );
};

export default BrandThree;
