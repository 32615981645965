import React from "react";
import deliverables from "../../data/pricing/deliverables.json";
import basic from "../../data/pricing/basic.json";
import advanced from "../../data/pricing/advanced.json";
import personalized from "../../data/pricing/personalized.json";
import { FiCheck, FiX } from "react-icons/fi";
import { Link } from "react-router-dom/cjs/react-router-dom.min";

export default function PricingBigScreen() {
  const checker = (type, deliverable_id) => {
    const val = type.find((x) => x.deliverable_id == deliverable_id);
    if (val) return <FiCheck />;
    return <FiX />;
  };
  const bodycontent = deliverables.map((d) => {
    return (
      <tr scope="row" key={d.id}>
        <th style={{ paddingLeft: "1.55rem" }}>{d.service}</th>
        <td style={{ textAlign: "center" }}>{checker(basic, d.id)}</td>
        <td style={{ textAlign: "center" }}>{checker(advanced, d.id)}</td>
        <td style={{ textAlign: "center" }}>{checker(personalized, d.id)}</td>
      </tr>
    );
  });
  return (
    <div className="row row--30">
      <table
        style={{ margin: "auto", fontSize: "1.17rem", maxWidth: "70rem" }}
        class="table table-borderless"
      >
        <thead style={{ background: "#FF731D" }}>
          <tr>
            <th
              style={{ verticalAlign: "middle", paddingLeft: "1.65rem" }}
              scope="col"
            >
              <div className="pricing">
                <div className="price-wrapper d-flex">
                  <span
                    className="fs-2"
                    style={{ fontWeight: "bold", color: "white" }}
                  >
                    Deliverables
                  </span>
                </div>
              </div>
            </th>
            <th style={{ verticalAlign: "middle" }} scope="col">
              <div className="pricing">
                <div className="price-wrapper d-flex justify-content-center">
                  <span
                    className="fs-2"
                    style={{ fontWeight: "bold", color: "white" }}
                  >
                    Basic
                  </span>
                </div>
              </div>
            </th>
            <th style={{ verticalAlign: "middle" }} scope="col">
              <div className="pricing">
                <div className="price-wrapper d-flex justify-content-center">
                  <span
                    className="fs-2"
                    style={{ fontWeight: "bold", color: "white" }}
                  >
                    Advanced
                  </span>
                </div>
                <div className="price-wrapper d-flex justify-content-center">
                  <span
                    className="fs-4"
                    style={{ fontWeight: "bold", color: "white" }}
                  >
                    (Recommended)
                  </span>
                </div>
              </div>
            </th>
            <th
              style={{ verticalAlign: "middle", textAlign: "center" }}
              scope="col"
            >
              <div className="pricing">
                <div className="price-wrapper d-flex justify-content-center">
                  <span
                    className="fs-2"
                    style={{
                      fontWeight: "bold",
                      color: "white",
                      marginBlock: "1em",
                    }}
                  >
                    Personalized
                  </span>
                </div>
              </div>
            </th>
          </tr>
        </thead>
        <tbody className="table-group-divider">
          {bodycontent}
          <tr>
            <th className="fs-2" style={{ paddingLeft: "1.55rem" }}>
              {/* <p className="fs-2">Fee per student (inc. GST)</p> */}
            </th>
            <td className="fs-2" style={{ textAlign: "center" }}>
            </td>
            <td className="fs-2" style={{ textAlign: "center" }}>
              <div className="pricing-footer mt--30 d-flex justify-content-center">
                <Link className="btn-default fs-5" to={'/contact'} >
                  Get Quotes
                </Link>
              </div>
            </td>
            <td className="fs-2" style={{ textAlign: "center" }}>
              {/* <p className="fs-2">5900 INR/Year</p> */}
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  );
}
