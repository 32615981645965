import React from 'react';
import TrackVisibility from "react-on-screen";
import Skill from "./Skill";

const progressData = [
    {
      id: 1,
      title: "Students - Rated 4/5, 5/5 star",
      percantage: 95,
      progressColor: "#059DFF",
    },
    {
      id: 2,
      title: "Teachers - Rated 4/5, 5/5 star",
      percantage: 83,
      progressColor: "#FB5343",
    },
    {
      id: 3,
      title: "School principals - Rated 4/5, 5/5 star",
      percantage: 98,
      progressColor: "#E33FA1",
    },
    // {
    //   id: 4,
    //   title: "Application Development",
    //   percantage: 95,
    //   progressColor: "#6549D5",
    // },
];

const ProgressbarOne = () => {
    return (
        <>
            {progressData.map((progress) => (
            <TrackVisibility
                once
                key={progress.id}
                className="single-progress"
            >
                <Skill progress={progress} /> 
            </TrackVisibility>
            ))} 
        </>
    )
}


export default ProgressbarOne;
