import React from "react";
import GradeFormField from "./GradeFormField";

const GradeForm = () => {
  return (
    <>

      <div className="row row--15">
        <div className="col-lg-6 m-auto">
          <GradeFormField formStyle="contact-form-1" />
        </div>
        {/* <div className="col-lg-5 mt_md--30 mt_sm--30">
          <GoogleMapStyle />
        </div> */}
      </div>
    </>
  );
};
export default GradeForm;
