import React from 'react';
import ScrollAnimation from "react-animate-on-scroll";
import AccordionOne from '../../../elements/accordion/AccordionOne';
import { useLocation } from 'react-router-dom';

const HeadSplit = () => {
    const location = useLocation()
    console.log(location)
    return (
            <div className="rn-splite-style container">
                <div className="split-wrapper">
                        <div className="row no-gutters radius-10 align-items-center">
                                <h2 className='text-center' style={{color:'#F37326'}}>{location?.state?.Heading}</h2>
                            <div className="col-lg-12 col-xl-6 col-12">
                                    <AccordionOne customStyle="" />
                            </div>
                            <div className="col-lg-12 col-xl-6 col-12">
                                <div className="split-inner">
                                    <ScrollAnimation 
                                    animateIn="fadeInUp"
                                    animateOut="fadeInOut"
                                    animateOnce={true}>
                                       <div className="col-lg-12 col-xl-6 col-12">
                                <div className="thumbnail image-left-content">
                                    <img src={location?.state?.HeadingImage} alt="split Images" />
                                </div>
                            </div>
                                    </ScrollAnimation>
                                </div>
                        </div>
                    </div>
                </div>
            </div>
    )
}

export default HeadSplit
