import React from "react";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";

const TabOne = () => {
  return (
    <div>
      <div className="row">
        <div className="col-lg-12">
          <Tabs>
            <div className="row row--30 align-items-center">
              <div className="col-lg-5">
                <img
                  className="radius-small"
                  src="https://res.cloudinary.com/db0vkpdye/image/upload/v1702151809/Website%20homepae/Leadership_Mindset_Skill_Graph_aoqqvf.jpg"
                  alt="Corporate React Template"
                />
              </div>
              <div className="col-lg-7 mt_md--40 mt_sm--40">
                <div className="rn-default-tab">
                  <div className="tab-button-panel">
                    <TabList className="tab-button">
                      <Tab>
                        <div className="rn-tab-button">
                          <button>Mission</button>
                        </div>
                      </Tab>
                      <Tab>
                        <div className="rn-tab-button">
                          <button>Values</button>
                        </div>
                      </Tab>
                      <Tab>
                        <div className="rn-tab-button">
                          <button>Vision</button>
                        </div>
                      </Tab>
                    </TabList>
                  </div>

                  <div className="tab-content-panel">
                    <TabPanel>
                      <div className="rn-tab-content">
                        <div className="inner">
                          <p>
                          Our mission is to collaborate with schools and to provide them 
                          everything needed to deliver the best STEM education 
                          in the country, so that schools can deliver best hands-on and skill-based education
                          with the best resources and children can learn this from experts & can have 
                          exposure to opportunities and exposure of India's largest innovation hub.
                        
                     
                          </p>

                          {/* <p>
                            Quibusdam ipsa, deleniti soluta minima minus
                            asperiores doloribus enim vitae obcaecati fuga
                            assumenda laudantium nemo odio provident nulla
                            exercitationem tempore corrupti! Nemo.
                          </p> */}
                        </div>
                      </div>
                    </TabPanel>

                    <TabPanel>
                      <div className="rn-tab-content">
                        <div className="inner">
                          <ul style={{ listStyle: "none" }}>
                            <li>
                              {" "}
                              <span className="me-2">
                                &#x2713;
                              </span> Innovation{" "}
                            </li>
                            <li>
                              {" "}
                              <span className="me-2">
                                &#x2713;
                              </span> Empowerment{" "}
                            </li>
                            <li>
                              <span className="me-2">&#x2713;</span>{" "}
                              Collaboration
                            </li>
                            <li>
                              <span className="me-2">&#x2713;</span> Integrity
                            </li>
                          </ul>
                          {/* <p>Ipsum lorem dolor sit amet consectetur, adipisicing elit. Quibusdam ipsa, deleniti soluta minima minus asperiores doloribus enim vitae obcaecati fuga assumenda laudantium nemo odio provident nulla exercitationem tempore corrupti! Nemo.</p>

                                                        <p>Ipsa Quibusdam, deleniti soluta minima minus asperiores doloribus enim vitae obcaecati fuga assumenda laudantium nemo odio provident nulla exercitationem tempore corrupti! Nemo.</p> */}
                        </div>
                      </div>
                    </TabPanel>

                    <TabPanel>
                      <div className="rn-tab-content">
                        <div className="inner">
                          <p>
                          Creating an atmoshphere of hands-on learning and innovation in the school, where
                          students learn by playing, building, and collaborating. Acuiring 21st century skills
                          for modern readiness and providing opportunities to students so that they apply
                          the classroom knowledge in real-world and gain confidence and courage that 
                          how the skills & knowledge obtained in school can be applied in real-world and they can use 
                          it to use it for the benefit of themselves, society, or the nation.
                          </p>
                        </div>
                      </div>
                    </TabPanel>
                  </div>
                </div>
              </div>
            </div>
          </Tabs>
        </div>
      </div>
    </div>
  );
};

export default TabOne;
