import React from "react";
import HeaderOne from "./header/HeaderOne";
import HeaderTopNews from "./header/HeaderTopNews";
import FooterTwo from "./footer/FooterTwo";
import Copyright from "./footer/Copyright";

const Layout = ({ children, setModalShow}) => {
  return (
    <>
      <main className="page-wrapper">
        {/* <HeaderTopNews /> */}
        <HeaderOne
        setModalShow={setModalShow}
          btnStyle="btn-small round btn-icon"
          HeaderSTyle="header-not-transparent"
        />
        {children}
        {/* <FooterTwo /> */}
        <Copyright />
      </main>
    </>
  );
};
export default Layout;
