import React from "react";
import { Link } from "react-router-dom";
import ScrollAnimation from "react-animate-on-scroll";

const ServiceList = [
  {
    icon: "1",
    // title: "STEM lab </br> setup",
    description: "Create an atmoshphere of hands-on & skill-based education",
    img: "https://res.cloudinary.com/db0vkpdye/image/upload/v1699387078/Website%20STEM%20photo%20album/3-min_mivuz7.png",
  },
  {
    icon: "2",
    // title: "Learning program",
    description:
      "Deliver 21st-century skills & enable students to apply in real-world",
    img: "https://res.cloudinary.com/db0vkpdye/image/upload/v1699387078/Website%20STEM%20photo%20album/4-min_dvjmrd.png",
  },
  {
    icon: "3",
    // title: "Consumables and upgrade",
    description:
      "Connect students to IIT Madras ecosystem for exposure-opportunities",
    img: "https://res.cloudinary.com/db0vkpdye/image/upload/v1699387079/Website%20STEM%20photo%20album/5-min_ynkvst.png",
  },
  {
    icon: "4",
    // title: "Centralized management",
    description: "Students guided by experts & supported by team of excellence",
    img: "https://res.cloudinary.com/db0vkpdye/image/upload/v1699387079/Website%20STEM%20photo%20album/6-min_avashb.png",
  },
];

const ServiceFour = ({ textAlign, serviceStyle }) => {
  return (
    <div className="row row--15 service-wrapper ">
      {ServiceList.map((val, i) => (
        <div className="col-lg-3 col-md-6 col-sm-6 col-12" key={i}>
          <ScrollAnimation
            animateIn="fadeInUp"
            animateOut="fadeInOut"
            animateOnce={true}
          >
            <div className={`service ${serviceStyle} ${textAlign}`}>
              <div
                className="icon"
                style={{
                  width: "100px",
                  height: "100px",
                }}
              >
                <div className="line"></div>
                {val.icon}
              </div>
              <div className="content">
                <h4 className="title">
                  <Link
                    to="#service"
                    dangerouslySetInnerHTML={{ __html: val.title }}
                  ></Link>
                </h4>
                <p
                  className="description b1 color-gray mb--0"
                  style={{ fontSize: "16px" }}
                  dangerouslySetInnerHTML={{ __html: val.description }}
                ></p>
              </div>
            </div>
          </ScrollAnimation>
        </div>
      ))}
    </div>
  );
};
export default ServiceFour;