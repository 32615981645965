import React,{useState} from "react";
import SEO from "../../common/SEO";
import Layout from "../../common/Layout";
import BreadcrumbOne from "../breadcrumb/BreadcrumbOne";
import SectionTitle from "../sectionTitle/SectionTitle";
import GalleryOne from "./GalleryOne";
import HeaderTopBar from "../../common/header/HeaderTopBar";
import { Link } from "react-router-dom";
import { MyVerticallyCenteredModal } from "../../pages/Splash";
import CustomsVideo from "../CustomeVideo/CustomVideo";
import CustomImage from "../customImage/CustomImage";
import Separator from "../separator/Separator";
import VideoOne from "../video/VideoOne";

const PopupData = [
  {
    id: "01",
    image: "./images/portfolio/9.png",
    popupLink: [
      "https://www.youtube.com/watch?v=ed87q3LCQaY",
    ],
  },
  {
    id: "02",
    image: "./images/portfolio/19.png",
    popupLink: [
      "https://www.youtube.com/watch?v=1jsotIezIr8",
    ],
  },
  {
    id: "03",
    image: "./images/portfolio/17-door.png",
    popupLink: [
      "https://www.youtube.com/watch?v=yrgDJw0yopk",
    ],
  },
  {
    id: "04",
    image: "./images/portfolio/5.png",
    popupLink: ["https://www.youtube.com/watch?v=xlNndo7EYpA"],
  },
  {
    id: "05",
    image: "./images/portfolio/ti.jpeg",
    popupLink: [
      "https://www.youtube.com/watch?v=FO9_4Q5BKGA",
    ],
  },
  {
    id: "06",
    image: "./images/portfolio/bc.jpeg",
    popupLink: ["https://www.youtube.com/watch?v=kvP2dUR94a8&list=PL0TvZDs0SwVQRuvjAz_5MlakHNmODhNFw"],
  },
  {
    id: "07",
    image: "./images/portfolio/gc.jpeg",
    popupLink: [
      "https://www.youtube.com/watch?v=TnufNSsXtOk",
    ],
  },
  {
    id: "08",
    image: "./images/portfolio/7.png",
    popupLink: ["https://www.youtube.com/watch?v=qZBY72HZur4"],
  },
  {
    id: "09",
    image: "./images/portfolio/Va.jpeg",
    popupLink: [
      "https://www.youtube.com/watch?v=vj3LtlR_tlU",
    ],
  },
  {
    id: "10",
    image: "./images/portfolio/15.png",
    popupLink: ["https://www.youtube.com/watch?v=TTyBkei1eDY"],
  },
  {
    id: "11",
    image: "./images/portfolio/12.png",
    popupLink: [
      "https://www.youtube.com/watch?v=c6O322yGNuE",
    ],
  },
  {
    id: "12",
    image: "./images/portfolio/21.png",
    popupLink: ["https://www.youtube.com/watch?v=5Vqfwmlmm0Y"],
  },
  {
    id: "13",
    image: "./images/portfolio/3drone.jpeg",
    popupLink: [
      "https://www.youtube.com/watch?v=jXAaROmVoSs",
    ],
  },
  {
    id: "14",
    image: "./images/portfolio/ngps.jpeg",
    popupLink: ["https://www.youtube.com/watch?v=eVtjCpiMjMA"],
  },
  {
    id: "15",
    image: "./images/portfolio/cleaning.jpeg",
    popupLink: [
      "https://www.youtube.com/watch?v=RNxlPny-ZnQ",
    ],
  },
  {
    id: "16",
    image: "./images/portfolio/gesture.jpeg",
    popupLink: ["https://www.youtube.com/watch?v=E8HLCx4F7ps"],
  },
  {
    id: "17",
    image: "./images/portfolio/spider.jpeg",
    popupLink: [
      "https://www.youtube.com/watch?v=efiscetmBxc",
    ],
  },
  {
    id: "18",
    image: "./images/portfolio/bvmoutrfid.jpeg",
    popupLink: [
      "https://www.youtube.com/watch?v=d63seN7SIw0&list=PL0TvZDs0SwVTDqA_y6AiE7YGxefCJXaHD&index=53",
    ],
  },
  {
    id: "19",
    image: "./images/portfolio/spider2.jpeg",
    popupLink: [
      "https://www.youtube.com/watch?v=f5VjwZYtDN0",
    ],
  },
  {
    id: "20",
    image: "./images/portfolio/weather.jpeg",
    popupLink: ["https://www.youtube.com/watch?v=p9n5EiI-9ss"],
  },
  {
    id: "21",
    image: "./images/portfolio/droneout.jpeg",
    popupLink: ["https://www.youtube.com/watch?v=qost-lYtsPU"],
  },
];

// const PopupData = [
//   {
//     id: "01",
//     image:
//       "https://res.cloudinary.com/db0vkpdye/image/upload/v1679763731/Website%20homepae/smartenergyproject_twswo0.png",
//     popupLink: ["https://www.youtube.com/watch?v=VNXUVZnRnHU"],
//   },
//   {
//     id: "02",
//     image:
//       "https://res.cloudinary.com/db0vkpdye/image/upload/v1679764221/Website%20homepae/spiderproject_ovwnhx.jpg",
//     popupLink: ["https://www.youtube.com/watch?v=Wj47lxjqbsk"],
//   },
//   {
//     id: "03",
//     image:
//       "https://res.cloudinary.com/db0vkpdye/image/upload/v1679764018/Website%20homepae/helmetproject_jzxp5k.png",
//     popupLink: ["https://www.youtube.com/watch?v=JWIdmrcqwnQ"],
//   },
//   {
//     id: "04",
//     image:
//       "https://res.cloudinary.com/db0vkpdye/image/upload/v1679764157/Website%20homepae/wheelchairproject_ubjadu.png",
//     popupLink: ["https://www.youtube.com/watch?v=RknwelkXcb0"],
//   },
//   {
//     id: "05",
//     image:
//       "https://res.cloudinary.com/db0vkpdye/image/upload/v1679764797/Website%20homepae/droneproject_qv2t0s.png",
//     popupLink: ["https://www.youtube.com/watch?v=-dQSOdoxFkQ"],
//   },
//   {
//     id: "06",
//     image:
//       "https://res.cloudinary.com/db0vkpdye/image/upload/v1679764965/Website%20homepae/ARproject_nvrr9f.png",
//     popupLink: ["https://www.youtube.com/watch?v=MusOnUAQ8f4"],
//   },
//   {
//     id: "07",
//     image:
//       "https://res.cloudinary.com/db0vkpdye/image/upload/v1679763812/Website%20homepae/borewellproject_clrid0.png",
//     popupLink: ["./images/portfolio/ro.jpg"],
//   },
//   {
//     id: "08",
//     image: "./images/portfolio/ro2.jpg",
//     popupLink: ["https://www.youtube.com/watch?v=L0kYdnnGj0s"],
//   },
//   {
//     id: "09",
//     image: "./images/portfolio/pe.jpg",
//     popupLink: ["./images/portfolio/pe.jpg"],
//   },
//   {
//     id: "10",
//     image: "./images/portfolio/pe5.jpg",
//     popupLink: ["./images/portfolio/pe5.jpg"],
//   },
//   {
//     id: "11",
//     image: "./images/portfolio/ro5.jpg",
//     popupLink: ["./images/portfolio/ro5.jpg"],
//   },
//   {
//     id: "12",
//     image: "./images/portfolio/ro6.jpg",
//     popupLink: ["./images/portfolio/ro6.jpg"],
//   },
// ];

// const PopupData3 = [
//   {
//     id: "01",
//     image: "./images/portfolio/ro11.jpg",
//     popupLink: [
//       "./images/portfolio/ro11.jpg",
//       "https://www.youtube.com/watch?v=ctsT5Y-InqE&ab_channel=Rainbow-Themes",
//       "./images/portfolio/portfolio-03.jpg",
//       "./images/portfolio/portfolio-04.jpg",
//       "https://www.youtube.com/watch?v=ctsT5Y-InqE&ab_channel=Rainbow-Themes",
//       "./images/portfolio/portfolio-05.jpg",
//     ],
//   },
//   {
//     id: "02",
//     image: "./images/portfolio/ro12.jpg",
//     popupLink: ["https://www.youtube.com/watch?v=L0kYdnnGj0s"],
//   },
//   {
//     id: "03",
//     image: "./images/portfolio/ro13.jpg",
//     popupLink: ["./images/portfolio/ro13.jpg"],
//   },
//   {
//     id: "04",
//     image: "./images/portfolio/ro14.jpg",
//     popupLink: ["./images/portfolio/ro14.jpg"],
//   },
//   {
//     id: "05",
//     image: "./images/portfolio/ro15.jpg",
//     popupLink: ["./images/portfolio/ro15.jpg"],
//   },
//   {
//     id: "06",
//     image: "./images/portfolio/ro16.jpg",
//     popupLink: ["./images/portfolio/ro16.jpg"],
//   },
// ];

// const PopupData2 = [
//   {
//     id: "01",
//     image: "./images/portfolio/gd.png",
//     popupLink: ["./images/portfolio/gd.png"],
//   },
//   {
//     id: "02",
//     image: "./images/portfolio/gd5.png",
//     popupLink: ["./images/portfolio/gd5.png"],
//   },
//   {
//     id: "03",
//     image: "./images/portfolio/gd3.png",
//     popupLink: ["./images/portfolio/gd3.png"],
//   },
//   {
//     id: "04",
//     image: "./images/portfolio/gd4.png",
//     popupLink: ["./images/portfolio/gd4.png"],
//   },
//   {
//     id: "05",
//     image: "./images/portfolio/gd8.jpg",
//     popupLink: ["./images/portfolio/gd8.jpg"],
//   },
//   {
//     id: "06",
//     image: "./images/portfolio/gd7.jpg",
//     popupLink: ["./images/portfolio/portfolio-05.jpg"],
//   },
//   {
//     id: "07",
//     image: "./images/portfolio/gd6.jpg",
//     popupLink: ["./images/portfolio/portfolio-06.jpg"],
//   },
//   {
//     id: "08",
//     image: "./images/portfolio/gd9.jpg",
//     popupLink: ["./images/portfolio/portfolio-01.jpg"],
//   },
//   {
//     id: "09",
//     image:
//       "https://drive.google.com/uc?export=view&id=1LnsLz3jJkrtFYqe50ER19eVe5h3GB6en",
//     popupLink: [
//       "https://drive.google.com/uc?export=view&id=1LnsLz3jJkrtFYqe50ER19eVe5h3GB6en",
//     ],
//   },
//   {
//     id: "10",
//     image:
//       "https://drive.google.com/uc?export=view&id=1LxwPuclzOuZFjlEWnniUG4Xe2pKbYTt0",
//     popupLink: [
//       "https://drive.google.com/uc?export=view&id=1LxwPuclzOuZFjlEWnniUG4Xe2pKbYTt0",
//     ],
//   },
//   {
//     id: "11",
//     image:
//       "https://drive.google.com/uc?export=view&id=1Ml8JoruQr9erZTaPBbr9hZzeCsPzc35T",
//     popupLink: [
//       "https://drive.google.com/uc?export=view&id=1Ml8JoruQr9erZTaPBbr9hZzeCsPzc35T",
//     ],
//   },
//   {
//     id: "12",
//     image:
//       "https://drive.google.com/uc?export=view&id=1MltwQOioAvJ-T9hnrCjDHMRDy57zl8Jk",
//     popupLink: [
//       "https://drive.google.com/uc?export=view&id=1MltwQOioAvJ-T9hnrCjDHMRDy57zl8Jk",
//     ],
//   },
//   {
//     id: "13",
//     image:
//       "https://drive.google.com/uc?export=view&id=1CdKCxJFk_CCcPvvexIUdig_9ajhezLl2",
//     popupLink: [
//       "https://drive.google.com/uc?export=view&id=1CdKCxJFk_CCcPvvexIUdig_9ajhezLl2",
//     ],
//   },
//   {
//     id: "14",
//     image:
//       "https://drive.google.com/uc?export=view&id=1MuQxKETNyV2NgCKSmzWg_d-1kX6hUfC9",
//     popupLink: [
//       "https://drive.google.com/uc?export=view&id=1MuQxKETNyV2NgCKSmzWg_d-1kX6hUfC9",
//     ],
//   },
//   {
//     id: "15",
//     image:
//       "https://drive.google.com/uc?export=view&id=10In56HsY35clYoE9PF-wb6Ysg-7Pxc7V",
//     popupLink: [
//       "https://drive.google.com/uc?export=view&id=10In56HsY35clYoE9PF-wb6Ysg-7Pxc7V",
//     ],
//   },
//   {
//     id: "16",
//     image:
//       "https://drive.google.com/uc?export=view&id=1M49OPaXrJSZZSzALiS4MY5HiageJQ3lW",
//     popupLink: [
//       "https://drive.google.com/uc?export=view&id=1M49OPaXrJSZZSzALiS4MY5HiageJQ3lW",
//     ],
//   },
// ];

const Elements = () => {
  const [modalShow, setModalShow] = useState(false);
  return (
    <>
      <MyVerticallyCenteredModal
        show={modalShow}
        onHide={() => setModalShow(false)}
      />
      <SEO title="Gallery || Qwings " />
      <HeaderTopBar />
      <Layout setModalShow={setModalShow}>
        <div className="breadcrumb-area ptb--120">
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <div className="breadcrumb-inner text-center">
                  <h1 className="title h2" style={{ color: "#FF731D" }}>
                    A glimpse of students projects <br /> from Qwings™ powered
                    schools
                  </h1>
                  <ul className="page-list">
                    <li className="rn-breadcrumb-item">
                      <Link to="Home">Home</Link>
                    </li>
                    <li className="rn-breadcrumb-item active">Gallery</li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="main-content">
          {/* Start Elements Area  */}

          {/* <div className="rwt-gallery-area rn-section-gap">
            <div className="container">
              <div className="row">
                <div className="col-lg-12 mb--20">
                  <SectionTitle
                    textAlign="text-center"
                    radiusRounded=""
                    subtitle="Students moments"
                    title="Captured by us."
                    description=""
                  />
                </div>
              </div>
              <div className="row mt_dec--30 row--15">
                {PopupData.map((item) => (
                  <div
                    className="col-lg-6 col-md-6 col-sm-6 col-12 mt--30"
                    key={item.id}
                  >
                    <GalleryOne galleryItem={item} />
                  </div>
                ))}
              </div>
            </div>
          </div> */}
          {/* End Elements Area  */}

          {/* Start Elements Area  */}
          <div className="rwt-gallery-area rn-section-gapBottom">
            <div className="container">
              <div className="row">
                <div className="col-lg-12 mb--20">
                  {/* <SectionTitle
                    textAlign="text-center"
                    radiusRounded=""
                    subtitle="Raw snaps from students"
                    title="Captured by students"
                    description=""
                  /> */}
                </div>
              </div>
              <div className="row mt_dec--30 row--15">
                {PopupData.map((item) => (
                  <div
                    className="col-lg-4 col-md-6 col-sm-6 col-12 mt--30"
                    key={item.id}
                  >
                    <GalleryOne galleryItem={item} />
                  </div>
                ))}
              </div>
            </div>
          </div>
          {/* End Elements Area  */}

          {/* Start Elements Area  */}
          {/* <div className="rwt-gallery-area rn-section-gapBottom">
            <div className="container">
              <div className="row">
                <div className="col-lg-12 mb--20">
                  <SectionTitle
                    textAlign="text-center"
                    radiusRounded=""
                    subtitle="Raw snaps from students"
                    title="Shared by students."
                    description=""
                  />
                </div>
              </div>
              <div className="row mt_dec--30 row--15">
                {PopupData2.map((item) => (
                  <div
                    className="col-lg-3 col-md-6 col-sm-6 col-12 mt--30"
                    key={item.id}
                  >
                    <GalleryOne galleryItem={item} />
                  </div>
                ))}
              </div>
            </div>
          </div> */}
          {/* End Elements Area  */}

          <Separator/>
          <div className="container mt--60"> 
              <CustomImage/>
          </div>
        </div>
        
      </Layout>
    </>
  );
};
export default Elements;
