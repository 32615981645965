import React, { useState } from "react";
import SEO from "../common/SEO";
import HeaderTopNews from "../common/header/HeaderTopNews";
import HeaderTopBar from "../common/header/HeaderTopBar";
import HeaderOne from "../common/header/HeaderOne";
import FooterThree from "../common/footer/FooterThree";
import BrandThree from "../elements/brand/BrandThree";
import AboutFour from "../elements/about/AboutFour";
import ServiceOne from "../elements/service/ServiceOne";
import SectionTitle from "../elements/sectionTitle/SectionTitle";
import CounterUpFour from "../elements/counterup/CounterUpFour";
import TeamFour from "../elements/team/TeamFour";
import TimelineTwo from "../elements/timeline/TimelineTwo";
import Separator from "../elements/separator/Separator";
import AboutFounder from "../elements/about/AboutFounder";
import { MyVerticallyCenteredModal } from "./Splash";
import SlpitOne from "../elements/split/SlpitOne";
import TabOne from "../elements/tab/TabOne";
import SlipFour from "../elements/split/SlipFour";
import TeamTwo from "../elements/team/TeamTwo";
import TeamOne from "../elements/team/TeamOne";

const AboutUs = () => {
  const [modalShow, setModalShow] = useState(false);
  return (
    <>
      <MyVerticallyCenteredModal
        show={modalShow}
        onHide={() => setModalShow(false)}
      />
      <SEO title="About Us" />
      <main className="page-wrapper">
        <HeaderTopBar />
        {/* <HeaderTopNews /> */}
        <HeaderOne
          setModalShow={setModalShow}
          btnStyle="btn-small round btn-icon"
          HeaderSTyle="header-transparent"
        />

        {/* Start Slider Area  */}
        {/* <div
          className="slider-area slider-style-1 height-850 bg_image"
          data-black-overlay="7"
          style={{
            backgroundImage: `url(${process.env.PUBLIC_URL}/images/bg/creative-bg.jpg)`,
          }}
        >
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <div className="inner pt--80 text-center">
                  <div>
                    <h3 className="rn-sub-badge">
                      <span className="theme-gradient">
                        Our Company's About Details.
                      </span>
                    </h3>
                  </div>
                  <h4 className="title display-one" style={{fontSize:"40px"}}>
                    Creating Deep-Tech <br /> STEM Learning Solutions for schools
                  </h4>
                </div>
              </div>
            </div>
          </div>
        </div> */}
        {/* End Slider Area  */}

        <div className="rwt-split-area rn-section-gapTop">
          <div className="wrapper">
            <div className="row">
              <div className="col-lg-12 mb--40">
                <SectionTitle
                  textAlign="text-center"
                  radiusRounded=""
                  subtitle="Qwings™"
                  title="An IIT Madras Incubated Company"
                  description=""
                />
              </div>
            </div>
            <SlpitOne />
          </div>
        </div>

        <div className="rwt-tab-area rn-section-gap">
          <div className="container">
            <div className="row mb--40">
              <div className="col-lg-12">
                <SectionTitle
                  textAlign="text-center"
                  radiusRounded=""
                  subtitle="Objectives"
                  title="Our Core Principles"
                  description=""
                />
              </div>
            </div>
            <TabOne />
          </div>
        </div>

        {/* Start Service Area  */}
        <div className="service-area rn-section-gapTop">
        
          <div className="container">
          <div className="content">
                  <h3 className="title">
                    Why Qwings™ 
                  </h3>
                </div>
              
              <div className="col-lg-12">
                <p className="mb--10">
                With the increased usage of computer science, AI, and automation in every industry, the current students in schools will become the workers, creators, policymakers, and innovators of the 2030s and beyond. Are we preparing these future innovators in the right way? 
                <br/><br/>
                Qwings™ is India’s first centralized STEM ecosystem wherein all the STEM resources for school education are being developed centrally, where we work on 6 major problems areas that is needed to deliver the 21st century education in the schools in a right way: 
                <br/><br/>
                1. <span style={{fontWeight: "bold"}}>Skilled Trainers :</span> A network of skilled STEM trainers and a backend team of domain experts who constantly support and upskill the trainers to ensure that the trainers are prepared to deliver this education in the right way. 
                <br/><br/>
                2. <span style={{fontWeight: "bold"}}>Pedagogy :</span> One of the major challenges is to engage every child in hands-on activity in the class-room therefore, having the right pedagogy is a very important aspect to ensure that every child can be engaged in hands-on activity. 
                <br/><br/>
                3. <span style={{fontWeight: "bold"}}>Learning Modules :</span>Creating systematic learning modules is needed to ensure that not only the students get exposure to 21st-century tools and technology, but they can also create derivatives by connecting the dots between various domains. 
                <br/><br/>
                4. <span style={{fontWeight: "bold"}}>Infrastructure :</span> Continually upgraded infrastructure is required to ensure that students do not lose interest or that the lab does not become outdated.
                <br/><br/>
                5. <span style={{fontWeight: "bold"}}>Quality Control & Management :</span> Dedicated teams for supply chain, trainer upskilling, performance tracking, and R&D are required to ensure that the lab items are replenished on time, trainers are prepared and focused on delivering lesson plans, performance and activity are being tracked on a daily basis and evaluated, and the R&D team is continually developing new projects to ensure lab growth. 
                <br/><br/>
                6. <span style={{fontWeight: "bold"}}>Innovation Support and Handholding :</span> To ensure that the unique ideas of the students do not go unnoticed, it would be a missed opportunity for the student, school, or nation. An ecosystem of domain experts, innovation hub support, and handholding is the need of the hour.

                  
                </p>
              </div>
            </div>
          </div>
       
        {/* End Service Area  */}


        <div className="rwt-split-area rn-section-gap ">
          <div className="wrapper">
            <div className="row">
              <div className="col-lg-12 mb--40">
                <SectionTitle
                  textAlign="text-center"
                  radiusRounded=""
                  subtitle="Education"
                  title="India - Vision @ 2047"
                  description=""
                />
              </div>
            </div>
            <SlipFour />
          </div>
        </div>
        <Separator />

        {/* Start Service Area  */}
        <div className="service-area rn-section-gapTop">
          <div className="container">
            <div className="row">
              <div className="col-lg-6">
                <div className="content">
                  <h3 className="title">Our Teams Message</h3>
                </div>
              </div>
              <div className="col-lg-6">
                <p className="mb--10">
                  We take pride in our reputation as the provider of the most
                  advanced futuristic STEM learning ecosystem in India, and that
                  naturally extends to a passion for making a difference in our
                  communities and in nation. Qwings™ pioneers in the field of
                  STEM education, and has developed one of the most advanced
                  STEM ecosystems in the country, with the help of IIT Madras
                  research park, India's most advanced deep-tech research
                  ecosystem. We have developed an ecosystem for schools that
                  will continue to operate school STEM labs and keeps upgrading
                  and advancing for many years to come.
                </p>
              </div>
            </div>
          </div>
        </div>
        {/* End Service Area  */}

        {/* Start Brand Area  */}
        {/* <div className="rwt-brand-area pb--60 pt--30">
          <div className="container">
            <div className="row">
              <div className="col-lg-12 mt--10">
                <BrandThree brandStyle="brand-style-2" />
              </div>
            </div>
          </div>
        </div> */}
        {/* End Brand Area  */}

        {/* <Separator /> */}

        {/* Start Service Area  */}
        {/* <div className="rn-service-area rn-section-gap">
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <SectionTitle
                  textAlign="text-center"
                  radiusRounded=""
                  subtitle="What we can do for you"
                  title="State-of-the-art STEM lab for schools"
                  description="We take full ownership to deliver cutting-edge STEM lab and learning program at school <br /> where the students can play, learn, innovate,
and convert their ideas into prototypes and patents."
                />
              </div>
            </div>
            <ServiceOne
              serviceStyle="service__style--1 bg-color-blackest radius mt--25"
              textAlign="text-center"
            />
          </div>
        </div> */}
        {/* End Service Area  */}

        {/* <div className="rwt-timeline-area rn-section-gapBottom">
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <TimelineTwo classVar="no-gradient" />
              </div>
            </div>
          </div>
        </div> */}

        <Separator />
       

        {/* <AboutFour image="./images/about/contact-image.jpg" /> */}

        <AboutFounder setModalShow={setModalShow} />

        {/* Start Elements Area  */}
        {/* <div className="rwt-counterup-area pb--100">
          <div className="container mt_dec--30">
            <CounterUpFour
              column="col-lg-3 col-md-6 col-sm-6 col-12"
              counterStyle="counter-style-4"
              textALign="text-center"
            />
          </div>
        </div> */}
        {/* End Elements Area  */}

        {/* <Separator /> */}

        {/* Start Elements Area  */}
        {/* <div className="rwt-team-area rn-section-gap">
          <div className="container">
            <div className="row mb--20">
              <div className="col-lg-12">
                <SectionTitle
                  textAlign="text-center"
                  radiusRounded=""
                  subtitle="Our Corporate Team."
                  title="Corporate Team Member."
                  description=""
                />
              </div>
            </div>
            <TeamTwo
              column="col-lg-6 col-xl-3 col-md-6 col-12 mt--30"
              teamStyle="team-style-two"
            />
          </div>
        </div> */}
        {/* End Elements Area  */}

       {/* Start Elements Area  */}
       <div className="rwt-team-area rn-section-gapBottom">
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <SectionTitle
                  textAlign="text-center"
                  radiusRounded=""
                  subtitle="Our Core Team"
                  title="Co-Founders"
                  description=""
                />
              </div>
            </div>
            <TeamOne
              column="col-lg-4 col-md-6 col-12 mt--30"
              teamStyle="team-style-default"
            />
          </div>
        </div>
        {/* End Elements Area  */}

        <FooterThree setModalShow={setModalShow} />
      </main>
    </>
  );
};

export default AboutUs;
