import React, { useState, useEffect } from "react";
import HeaderOne from "../../common/header/HeaderOne";

import ScrollAnimation from "react-animate-on-scroll";
import { Link } from "react-router-dom";
import { grades, arradata } from "./Constants";
import "./ArrayComonent.css";
import SEO from "../../common/SEO";
import HeaderTopBar from "../../common/header/HeaderTopBar";
import FooterThree from "../../common/footer/FooterThree";
import Separator from "../../elements/separator/Separator";

function ArrayComponent() {
  const [modalShow, setModalShow] = useState(false);
  const [value, Setvalue] = useState(arradata);
  const [SelectedVal, SetSelectedVal] = useState("All Grades");

  const changetext = (event) => {
    // alert(event.target.value)
    SetSelectedVal(event.target.value);
  };

  useEffect(() => {
    let updated = arradata.filter((val) => {
      if (SelectedVal == "All Grades") {
        return arradata;
      }
      return val.name == SelectedVal ? val : null;
    });
    Setvalue(updated);
  }, [SelectedVal]);

  const GotoHead = (data) =>{
      console.log(data)
  }
  return (
    <>
      <SEO title="About Us" />
      <main className="page-wrapper">
        <HeaderTopBar />
        <HeaderOne
          setModalShow={setModalShow}
          btnStyle="btn-small round btn-icon"
          HeaderSTyle="header-transparent"
        />

        {/* <div
        className="slider-area slider-style-1 height-850 bg_image"
        data-black-overlay="7"
        style={{
          backgroundImage: `url(${process.env.PUBLIC_URL}/images/bg/creative-bg.jpg)`,
        }}
      >
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div className="inner pt--80 text-center">
                <div>
                  <h3 className="rn-sub-badge">
                    <span className="theme-gradient">
                      Our Company's About Details.
                    </span>
                  </h3>
                </div>
                <h4 className="title display-one" style={{ fontSize: "40px" }}>
                  Creating Deep-Tech <br /> STEM Learning Solutions for schools
                </h4>
              </div>
            </div>
          </div>
        </div>
      </div> */}

        <div className="row row--15 service-area rn-section-gapTop">
          <div className="row container">
            <div className="col-12">
              <ul
                className="rwt-portfolio-filter filter-button-default liststyle mb--20"
                style={{ alignItems: "baseline", gap: "10px" }}
              >
                <li style={{ color: "#F37326", fontWeight: "700" }}>
                  {" "}
                  Choose Grade
                </li>
                <li>
                  <div class="select">
                    <select
                      name="format"
                      id="format"
                      value={SelectedVal}
                      onChange={changetext}
                      style={{ color: "black" }}
                    >
                      {grades.map((option) => (
                        <option value={option.name} >{option.name}</option>
                      ))}
                    </select>
                  </div>

                  {/* <select
                  value={SelectedVal}
                  onChange={changetext}
                  style={{
                    border: "1px solid black",
                    appearance: "auto",
                    color: "black",
                  }}
                >
                  {grades.map((option) => (
                    <option value={option.name}>{option.name}</option>
                  ))}
                </select> */}
                </li>
              </ul>
            </div>
          </div>

          <div className="row">
            <div
              className={`service service__style--1 bg-color-blackest radius mt--25" text-center`}
            >
              <div className="container mt-2">
                <div className="row" style={{ rowGap: "20px" }}>
                  <div className="col-12 ">
                    <p
                      className="btn"
                      style={{
                        textAlign: "center",
                        color: "black",
                        fontSize: "20px",
                      }}
                    >
                      {SelectedVal}
                    </p>
                  </div>
                </div>
                <div className="row" style={{ rowGap: "20px" }}>
                  {Array.isArray(value)
                    ? value.map((myvalue) => {
                        return myvalue.data.map((mydata) => {
                          return (
                            <>
                              <div className="row">
                                <div className="co-12">
                                  <p
                                    style={{
                                      textAlign: "left",
                                      color: "#F37326",
                                      fontWeight: "bold",
                                    }}
                                  >
                                    {mydata.heading}
                                  </p>
                                </div>
                              </div>
                             {mydata.arraydata.map((data,index) => {
                                return (
                                  <div className="col-md-3 col-sm-6 item" onClick={()=> GotoHead(mydata?.images[index])}>
                                  <Link to={{pathname: `gradeSections/${data}`, state: {HeadingImage:mydata?.images[index],Heading:data} }} >
                                    <div
                                      className="card item-card card-block"
                                      style={{
                                        borderRadius: "10px 10px",
                                        border: "1px solid black",
                                      }}
                                    >
                                      <div>
                                        <img
                                          src={mydata?.images[index] || "https://static.pexels.com/photos/7096/people-woman-coffee-meeting.jpg"}
                                          style={{ borderRadius: "6px",minHeight:"180px",maxHeight:'180px' }}
                                        />
                                        <h5
                                          className="item-card-title mt-3 mb-3"
                                          style={{ color: "#F37326" }}
                                        >
                                          {data}
                                        </h5>
                                        <p className="card-text">{data}</p>
                                      </div>
                                    </div>
                                  </Link>
                                  </div>
                                );
                              })}
                            </>
                          );
                        });
                      })
                    : value
                    ? value.data.map((mydata) => {
                        return (
                          <>
                            <div className="row">
                              <div className="co-12">
                                <p
                                  style={{ textAlign: "left", color: "black" }}
                                >
                                  {mydata.heading}
                                </p>
                              </div>
                            </div>
                            {mydata.arraydata.map((data) => {
                              return (
                                <div className="col-md-3 col-sm-6 item" onClick={GotoHead}>
                                  <div className="card item-card card-block">
                                    <h4 className="card-title text-right">
                                      <i className="material-icons">data</i>
                                    </h4>
                                    <img
                                      src="https://static.pexels.com/photos/7096/people-woman-coffee-meeting.jpg"
                                      alt="Photo of sunset"
                                    />
                                    <h5 className="item-card-title mt-3 mb-3">
                                      {data}
                                    </h5>
                                    <p className="card-text">{data}</p>
                                  </div>
                                </div>
                              );
                            })}
                          </>
                        );
                      })
                    : null}
                </div>
              </div>
            </div>
          </div>
        </div>
        <Separator />
        <FooterThree />
      </main>
    </>
  );
}

export default ArrayComponent;
