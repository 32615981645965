import React, { useState } from "react";
import axios from "axios";
import emailjs from "emailjs-com";

const Result = () => {
  return (
    <p className="success-message">
      Your Message has been successfully sent. I will contact you soon.
    </p>
  );
};

function ContactForm({ props, formStyle }) {
  const [result, showresult] = useState(false);
  const [data, Setdata] = useState({
    name: "",
    number: "",
    message: "",
  });
  const sendEmail = (e) => {
    e.preventDefault();
    // emailjs
    //   .sendForm(
    //     "service_p4x3hv8",
    //     "template_jgfr42f",
    //     e.target,
    //     "user_jrfTH2e0Ely35ZCVFdT9S"
    //   )
    //   .then(
    //     (result) => {
    //       console.log(result.text);
    //     },
    //     (error) => {
    //       console.log(error.text);
    //     }
    //   );
    axios
      .post("/savecontact", data)
      .then((response) => {
        // Handle response
        alert("Thank You Submitted Successfully");
        Setdata({
          name: "",
          number: "",
          message: "",
        });
      })
      .catch((err) => {
        // Handle errors
        console.error(err);
      });

    showresult(true);
  };

  setTimeout(() => {
    showresult(false);
  }, 5000);

  return (
    <form className={`${formStyle}`} action="" onSubmit={sendEmail}>
      <div className="form-group">
        <input
          type="text"
          name="name"
          placeholder="Your Name"
          value={data.name}
          required
          onChange={(e) => {
            Setdata({ ...data, [e.target.name]: e.target.value });
          }}
        />
      </div>

      {/* <div className="form-group">
                <input 
                type="email"
                name="email"
                placeholder="Email Address"
                required
                />
            </div> */}

      <div className="form-group">
        <input
          type="text"
          name="number"
          placeholder="Phone Number"
          value={data.number}
          required
          max={10}
          min={10}
          onChange={(e) => {
            Setdata({ ...data, [e.target.name]: e.target.value });
          }}
        />
      </div>

      {/* <div className="form-group">
                <input 
                type="text"
                name="subject"
                placeholder="Subject"
                required
                />
            </div> */}

      <div className="form-group">
        <textarea
          name="message"
          placeholder="Your Message"
          required
          value={data.message}
          onChange={(e) => {
            Setdata({ ...data, [e.target.name]: e.target.value });
          }}
        ></textarea>
      </div>

      <div className="form-group">
        <button className="btn-default btn-large">Submit Now</button>
      </div>

      <div className="form-group">{result ? <Result /> : null}</div>
    </form>
  );
}
export default ContactForm;
