import React from 'react';
import { FiFacebook, FiTwitter, FiInstagram, FiLinkedin } from "react-icons/fi";
import {Link} from "react-router-dom";
import ScrollAnimation from "react-animate-on-scroll";

const teamData = [
    {
        image: '2',
        name: 'Dr. Sunny Sharma',
        designation: 'Chief of Operations',
        location: 'IIT Madras Research Park', 
        description: 'MS, PhD from IIT Madras in Metal 3D Printing Technology',
        socialNetwork: [
            {
                icon: <FiLinkedin />,
                url: 'https://www.linkedin.com/in/sunnysharma200?utm_source=share&utm_campaign=share_via&utm_content=profile&utm_medium=android_app'
            },
            // {
            //     icon: <FiTwitter />,
            //     url: '#'
            // },
            // {
            //     icon: <FiInstagram />,
            //     url: '#'
            // },
        ]
       
    },
    {
        image: '1',
        name: 'Dr. Vagish Mishra',
        designation: 'Chief of technology',
        location: 'IIT Madras Research Park', 
        description: 'MS, PhD from IIT Madras in Defence Technology',
        socialNetwork: [
            {
                icon: <FiLinkedin />,
                url: 'https://www.linkedin.com/in/vagish-d-mishra-5b8ba473?utm_source=share&utm_campaign=share_via&utm_content=profile&utm_medium=android_app'
            },
            // {
            //     icon: <FiTwitter />,
            //     url: '#'
            // },
            // {
            //     icon: <FiLinkedin />,
            //     url: '#'
            // },
        ]
      
    },
    {
        image: '3',
        name: 'Shubhangi Kathe',
        designation: 'Sr. Product Manager',
        location: 'IIT Madras Research Park', 
        description: 'M-Tech from IIT Madras in Computer Science',
        socialNetwork: [
            {
                icon: <FiLinkedin />,
                url: 'https://www.linkedin.com/in/shubhangikathe?utm_source=share&utm_campaign=share_via&utm_content=profile&utm_medium=android_app'
            },
        ]
        
    },
]


const TeamOne = ({column , teamStyle}) => {
    return (
        <div className="row row--15">
            {teamData.map((data, index) => (
                <div className={`${column}`} key={index}>
                    <div className={`rn-team ${teamStyle}`}>
                        <ScrollAnimation 
                        animateIn="fadeInUp"
                        animateOut="fadeInOut"
                        animateOnce={true}>
                            <div className="inner">
                                <figure className="thumbnail">
                                    <img src={`./images/team/${data.image}.jpg`} alt="Corporate React Template" />
                                </figure>
                                <figcaption className="content">
                                    <h4>{data.name}</h4>
                                    <h6>{data.designation}</h6>
                                    <span className="team-form">
                                        <img src="./images/team/location.svg" alt="Corporate React Template" />
                                        <span className="location">{data.location}</span>
                                    </span>
                                    <p className="description">{data.description}</p>

                                    <ul className="social-icon social-default icon-naked mt--20" >
                                        {data.socialNetwork.map((social, index) =>
                                            <li key={index}><a href={`${social.url}`}>{social.icon}</a></li>
                                        )}
                                    </ul>
                                </figcaption>
                            </div>
                        </ScrollAnimation>
                    </div>
                </div>
            ))}
        </div>
    )
}

export default TeamOne;
