import React from "react";
import SEO from "../../common/SEO";
import Layout from "../../common/Layout";
import BreadcrumbOne from "../breadcrumb/BreadcrumbOne";
import SectionTitle from "../sectionTitle/SectionTitle";
import PricingOne from "./PricingOne";
import PricingTwo from "./PricingTwo";
import PricingThree from "./PricingThree";
import PricingFour from "./PricingFour";
import PricingFive from "./PricingFive";
import Separator from "../separator/Separator";
import PricingBigScreen from "./PricingBigScreen";

const Pricing = () => {
  return (
    <>
      <SectionTitle
        textAlign="text-center"
        radiusRounded=""
        subtitle="Our Offering for Schools"
        title="Get Plans"
        description=""
      />

      <div className="main-content">
        <div className="rwt-pricing-area mb--90 pricing--phone">
          <div className="container">
            <div className="container mb--40 mb_sm--0 ms-2 me-2 ">
              <PricingOne />
            </div>
          </div>
        </div>
        {/* End Elements Area  */}

        <Separator />

        {/* Start Elements Area  */}
        <div className="rwt-pricing-area mb--90 pricing--bigscreen">
          <div className="container">
            <div className="row mb--40 mb_sm--0">
            </div>
            <PricingBigScreen />
          </div>
        </div>
        {/* End Elements Area  */}
        <Separator />
      </div>
      {/* </Layout> */}
    </>
  );
};
export default Pricing;
