import React from "react";
import { FiActivity, FiCast, FiMap , FiCheck } from "react-icons/fi";
import { Link } from "react-router-dom";
import ScrollAnimation from "react-animate-on-scroll";

const ServiceList = [
  {
    icon: "https://res.cloudinary.com/db0vkpdye/image/upload/v1699387078/Website%20STEM%20photo%20album/3-min_mivuz7.png",
    title: "Deployment of </br> STEM Lab",
    description:
      "&check; Lab establishment</br>&check; STEM Kits </br> &check; Trainers </br> &check; Daily lab consumables </br>&check; Lab upgrades </br> &check; Study material </br> &check; Derivative projects </br> &check; Certificates",
  },
  {
    icon: "https://res.cloudinary.com/db0vkpdye/image/upload/v1699387078/Website%20STEM%20photo%20album/4-min_dvjmrd.png",
    title: "Activity based & </br> Learning Modules ",
    description:
      "&check; Grade 3-12th Learning program</br> &check; Activity Sheets</br> &check; Book</br> &check; LMS </br> &check; Assessments  </br> &check; Brainstorming </br> &check;Weekly plans and targets </br>   ",
  },

  {
    icon: "https://res.cloudinary.com/db0vkpdye/image/upload/v1699387079/Website%20STEM%20photo%20album/5-min_ynkvst.png",
    title: "Complete </br> Management",
    description:
      "&check; Performance Tracking </br>&check; Training Management</br>&check; Supply Chain</br> &check; Quality control  </br> &check;Lab management </br> &check;Daily Tracking </br> &check;Trainer upskilling </br> &check;Performance assessment",
  },
  {
    icon: "https://res.cloudinary.com/db0vkpdye/image/upload/v1699387079/Website%20STEM%20photo%20album/6-min_avashb.png",
    title: "Innovation Support & </br> Guidance",
    description:
      "&check; Tech-fests in school</br> &check;Participation in challenges</br> &check;National level presence </br> &check;Access to domain experts </br> &check;Regular videos of projects </br>&check; Specialized workshops</br>&check; Innovation & Entrepreneurial support ",
  },
];

const ServiceOne = ({ textAlign, serviceStyle }) => {
  return (
    <div className="row row--15 service-wrapper service-wrapper--serviceOne">
      {ServiceList.map((val, i) => (
        <div className="col-lg-3 col-md-6 col-sm-6 col-12 h-100" key={i}>
          <ScrollAnimation
            animateIn="fadeInUp"
            animateOut="fadeInOut"
            animateOnce={true}
          >
            <div className={`service ${serviceStyle} ${textAlign}`}>
              <div className="icon">
                <img
                  className="rounded"
                  // src="https://rainbowit.net/html/doob/assets/images/services/serviice-02.jpg"
                  src={val.icon}
                  alt=""
                />
              </div>
              <div className="content">
                <h4 className="title w-500 text-start fs-2 "><Link to="#service" dangerouslySetInnerHTML={{__html: val.title}}></Link></h4>
                {/* <h4 className="title w-600">
                  <Link
                    to="#service"
                    dangerouslySetInnerHTML={{ __html: "Awarded Design" }}
                  ></Link>
                </h4> */}
                <p className="description b1 color-gray mb--0 text-start fs-4" dangerouslySetInnerHTML={{__html: val.description}}></p>
                {/* <p
                  className="description b1 color-gray text-start mb--30"
                  dangerouslySetInnerHTML={{
                    __html:
                      "Variations There are many of pass ages of Lorem Ipsum available the majority.",
                  }}
                ></p> */}
                {/* <div className="d-flex align-left mt-4" >
                  <a className="btn-default btn-border" href="/contact">
                    Read more
                  </a>
                </div> */}
              </div>
            </div>
          </ScrollAnimation>
        </div>
      ))}
    </div>
  );
};
export default ServiceOne;
