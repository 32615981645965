import React from 'react';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';

const TabTwo = () => {
    return (
        <div>
            <div className="row">
                    <div className="col-lg-12">
                        <Tabs>
                            <div className="row row--30 align-items-center">
                                <div className="col-lg-5">
                                    <img className="radius-small" src='./images/tab/iitmrp.jpg' alt="Corporate React Template" style={{height:"300px"}}/>
                                </div>
                                <div className="col-lg-7 mt_md--40 mt_sm--40">
                                    <div className="rn-default-tab style-two">
                                        <div className="tab-button-panel">
                                            <TabList className="tab-button">
                                                <Tab>
                                                    <div className="rn-tab-button">
                                                        <button>Experties</button>
                                                    </div>   
                                                </Tab>
                                                <Tab>
                                                    <div className="rn-tab-button">
                                                        <button>Tech Force</button>
                                                    </div>   
                                                </Tab>
                                                <Tab>
                                                    <div className="rn-tab-button">
                                                        <button>IIT Research Park</button>
                                                    </div>   
                                                </Tab>
                                            </TabList>
                                        </div>

                                        <div className="tab-content-panel">
                                            <TabPanel>
                                                <div className="rn-tab-content">
                                                    <div className="inner">
                                                        <li>Skilled Network of Trainers</li>
                                                        
                                                        <li>Pedagogy to engage every children</li>
                                                       
                                                        <li>Systematic Learning Modules - all grades</li>
                                                        
                                                        <li>Customized Infrastructure</li>
                                                      
                                                        <li>Quality Control & Management</li>
                                                        
                                                        <li>Innovation Support and Handholding</li>
                                                    </div>
                                                </div>
                                            </TabPanel>
                                            
                                            <TabPanel>
                                                <div className="rn-tab-content">
                                                    <div className="inner">
                                                        <p>Our core team, domain experts, and the network of skilled trainers are working, interacting, upskilling, and teaching together, and is forming a tech force in the country. This team allow us to deliver best STEM education in the country.</p>

                                                        {/* <p>Ipsa Quibusdam, deleniti soluta minima minus asperiores doloribus enim vitae obcaecati fuga assumenda laudantium nemo odio provident nulla exercitationem tempore corrupti! Nemo.</p> */}
                                                    </div>
                                                </div>
                                            </TabPanel>

                                            <TabPanel>
                                                <div className="rn-tab-content">
                                                    <div className="inner">
                                                        <p>Connecting school students to IIT Research Park has been a great success so far. The exposure to IIT Research Park seems to be providing students with invaluable experiences that are boosting their confidence and inspiring them to apply their classroom knowledge to real-world challenges. This initiative is going to have a lasting impact on their academic and professional futures. </p>

                                                        {/* <p>Ipsa Quibusdam, deleniti soluta minima minus asperiores doloribus enim vitae obcaecati fuga assumenda laudantium nemo odio provident nulla exercitationem tempore corrupti! Nemo.</p> */}
                                                    </div>
                                                </div>
                                            </TabPanel>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </Tabs>
                    </div>
                </div>
        </div>
    )
}

export default TabTwo
