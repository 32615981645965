import React from "react";
import {
  FiFacebook,
  FiTwitter,
  FiInstagram,
  FiLinkedin,
  FiYoutube,
} from "react-icons/fi";

import { Link } from "react-router-dom";

const CopyrightTwo = () => {
  return (
    <div className="copyright-area copyright-style-one variation-two">
      <div className="container">
        <div className="row align-items-center">
          <div className="col-lg-4 col-md-7 col-sm-12 col-12">
            <div className="copyright-left">
              <ul className="ft-menu link-hover">
                <li>
                  <a href="/gallery">Gallery</a>
                </li>
                <li>
                  <a href="/about-us">About Us</a>
                </li>
                {/* <li>
                  <a href="https://qwings.org/">Learning platform</a>
                </li> */}
                {/* <li>
                  <a href="https://qrobotics.in/">
                    Robotics E-Commerce platform
                  </a>
                </li> */}
                <li>
                  <a href="career">Career</a>
                </li>
                <li>
                  <a href="privacypolicy">Privacy Policy</a>
                </li>
                <li>
                  <a href="termsandconditions">Terms And Condition</a>
                </li>
                <li>
                  <a href="/contact">Contact Us</a>
                </li>
              </ul>
            </div>
          </div>
          <div className="col-lg-4 col-md-5 mt_sm--20">
            <div className="copyright-center text-center">
              <ul className="social-icon social-default color-lessdark justify-content-center">
                <li>
                  <a href="https://www.facebook.com/QWingsIndia">
                    <FiFacebook />
                  </a>
                </li>
                <li>
                  <a href="https://mobile.twitter.com/Qwings_India">
                    <FiTwitter />
                  </a>
                </li>
                <li>
                  <a href="https://www.instagram.com/qwings_in/">
                    <FiInstagram />
                  </a>
                </li>
                <li>
                  <a href="https://www.linkedin.com/company/qwings/">
                    <FiLinkedin />
                  </a>
                </li>
                <li>
                  <a href="https://www.youtube.com/channel/UCfxwGvNdUJKfw5cBli-m3BQ">
                    <FiYoutube />
                  </a>
                </li>
              </ul>
            </div>
          </div>
          <div className="col-lg-4 col-md-12 col-sm-12 col-12 mt_md--20 mt_sm--20">
            <div className="copyright-right text-center text-lg-right">
              <p className="copyright-text">
                Copyright All rights reserved © {new Date().getFullYear()}{" "}
                Qwings™.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default CopyrightTwo;
