import React from "react";
import Typed from "react-typed";
import { Link } from "react-router-dom";
import { FiArrowRight } from "react-icons/fi";
import AccordionOne from "../accordion/AccordionOne";
import { Button } from "react-bootstrap";

const mission = (props) => {
  return (
    <div className="rn-company-mission-are rn-section-gap">
      <div className="container">
        <div className="row row--30">
          <div className="col-lg-6">
            <div className="mission-title">
              <h2 className="title">
                Our Product <br />{" "}
                {/* <Typed className="theme-gradient"
                                    strings={[
                                        "Consulting.",
                                        "Vission.",
                                        "Agency.",
                                    ]}
                                    typeSpeed={80}
                                    backSpeed={5}
                                    backDelay={1000}
                                    loop
                                /> */}
              </h2>
              <p>
                Qwings™ STEM Lab Ecosystem help schools across india in
                establishing and running an advanced STEM education at their
                school
              </p>

              <p>
                In this ecosystem, We take complete ownership in running the
                STEM lab at their school
              </p>

              <div className="read-more-btn mt--50">
                <Button
                  className="btn-default btn-icon"
                  onClick={() => props.setModalShow(true)}
                  style={{background:'#FF731D'}}
                >
                  Know More{" "}
                  <i className="icon">
                    <FiArrowRight />
                  </i>
                </Button>
              </div>
            </div>
          </div>
          <div className="col-lg-6 mt_md--30 mt_sm--30">
            <AccordionOne customStyle="" />
          </div>
        </div>
      </div>
    </div>
  );
};
export default mission;
